/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useEffect } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Button, Icon,
} from '@scuf/common';
import axios from 'axios';
import PageLayoutWorkflow from '../../components/PageLayoutWorkflow';

function Cardiology() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios
            .get('https://jsonplaceholder.typicode.com/users')
            .then((res) => {
                setPosts(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const actionButtons = () => (
        <Button
            onClick={() => { window.location.href = '/patient-workflow-view'; }}
            icon={<Icon name="document-user-profile" size="small" root="common" color="blue" />}
            type="link"
            content=""
        />
    );
    return (
        <div>
            <PageLayoutWorkflow data-testid="Cardiology" title="Cardiology">
                <div>
                    <DataTable data={posts} search searchPlaceholder="Search Records">
                        <DataTable.Column field="id" header="UHID" sortable />
                        <DataTable.Column field="name" header="Name" sortable />
                        <DataTable.Column field="email" header="Test Type" sortable />
                        <DataTable.Column field="testDate" header="Test Date" sortable />
                        <DataTable.Column field="fromToLocation" header="From-To Location" sortable />
                        <DataTable.Column
                            field="plane"
                            header="Workflow"
                            renderer={actionButtons}
                        />
                    </DataTable>
                </div>
            </PageLayoutWorkflow>

        </div>
    );
}
export default Cardiology;
