import React, { ReactElement, useState } from 'react';
import {
    TextArea, Button, DatePicker, Select, Modal, Icon,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';

import PageLayout from '../components/PageLayout';
import data from '../json/assignTest.json';

const AssignTest = (): ReactElement => {
    const [isAddModalOpenUnassign, setAddModalUnassign] = useState(false);
    const patientOptions = [
        { value: 'Aron Davis', text: 'Aron Davis' },
        { value: 'Jessica warren', text: 'Jessica warren' },
        { value: 'Tony Frank', text: 'Tony Frank' },
    ];
    const testOptions = [{ value: 'MRI', text: 'MRI' }, { value: 'X-Ray', text: 'X-Ray' }];
    const [contacts, setContacts] = useState(data);
    const [addFormData, setAddFormData] = useState({
        patientName: '',
        testName: '',
        testDate: '',
        testDescription: '',
    });
    const isSubmitEnabled = () => 1;
    const handleAddFormChange = (fieldName, fieldValue) => {
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };

    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        const newContact = {
            patientName: addFormData.patientName,
            testName: addFormData.testName,
            testDate: addFormData.testDate,
            testDescription: addFormData.testDescription,
        };
        const newContacts = [...contacts, newContact];
        setContacts(newContacts);
    };
    const headerRightButton = () => (
        <Button
            type="primary"
            content="Add Test"
            onClick={() => setAddModalUnassign(true)}
            icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
            iconPosition="left"
        />
    );

    return (
        <PageLayout title="Assign Test" headerRight={headerRightButton()}>
            <div>
                <DataTable data={contacts} search searchPlaceholder="Search Records">
                    <DataTable.Column field="patientName" header="Patient Name" sortable />
                    <DataTable.Column field="testName" header="Test" sortable />
                    <DataTable.Column field="testDate" header="Date" sortable />
                    <DataTable.Column field="testDescription" header="Description" sortable />
                </DataTable>
            </div>
            <Modal className="modal50" closeIcon onClose={() => setAddModalUnassign(false)} open={isAddModalOpenUnassign}>
                <form onSubmit={handleAddFormSubmit}>
                    <h2>Add Test</h2>
                    <div className="m10">
                        <span className="m10">
                            <Select
                                placeholder="Select Patient"
                                options={patientOptions}
                                name="patientName"
                                className="w18"
                                onChange={(value) => (handleAddFormChange('patientName', value))}
                            />
                        </span>
                        <span className="m10">
                            <Select
                                placeholder="Select Test"
                                options={testOptions}
                                name="testName"
                                className="w16"
                                onChange={(value) => (handleAddFormChange('testName', value))}
                                required="required"
                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <DatePicker
                                placeholder="Select Date"
                                name="testDate"
                                type="datetime"
                                className="w12 ml20"
                                onTextChange={(value) => (handleAddFormChange('testDate', value))}
                                required="required"
                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <TextArea
                                rows={1}
                                placeholder="Description..."
                                name="testDescription"
                                className="mh2"
                                onChange={(value) => (handleAddFormChange('testDescription', value))}
                                required="required"
                            />
                        </span>
                    </div>

                    <div className="m10">
                        <Button
                            type="primary"
                            actionType="submit"
                            disabled={!isSubmitEnabled()}
                        >
                            Add Test
                        </Button>
                    </div>
                </form>
            </Modal>
        </PageLayout>
    );
};
export default AssignTest;
