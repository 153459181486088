import React, { ReactElement } from 'react';
import { ThemeType, toggleTheme } from '@scuf/common';
import { Switch, Route } from 'react-router-dom';
import AppShell from './components/AppShell';
import routes from './routes';
import { IS_THEME_DARK } from './configuration';
import withAuthContext from './helpers/Auth/withAuth';
import Login from './pages/Login';

import { IAuth } from './models/IAuthModel';

toggleTheme(IS_THEME_DARK ? ThemeType.Dark : ThemeType.Light);
const App = ({ auth }: {auth: IAuth}): ReactElement => (
    <>
        {auth.isAuthenticated
            ? (
                <AppShell>
                    <Switch>
                        {routes.map(({ path, Component }) => (

                            <Route path={path}>

                                <Component />

                            </Route>
                        ))}
                    </Switch>
                </AppShell>
            )
            : (

                <Switch>
                    case Login :
                    {' '}
                    <Login />
                    {' '}
                    ;

                </Switch>

            )}
    </>
);

export default withAuthContext(App);
