/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import { DataTable } from '@scuf/datatable';
import { Icon, Button, Modal } from '@scuf/common';
import { dataComplete } from '../../../constanFile.js';
import CompleteEditModal from '../../../components/CompleteEditModal';

const CompletedTab = () => {
    const [isEditModalComplete, setEditModalComplete] = useState(false);

    const actionButtons = () => (
        <Button
            onClick={() => setEditModalComplete(true)}
            type="link"
            icon={<Icon name="badge-important" size="small" root="common" color="blue" />}
            content=""
        />
    );

    return (
        <>
            <DataTable data={dataComplete} search searchPlaceholder="Search Records">
                <DataTable.Column field="task_id" header="TASK ID" sortable />
                <DataTable.Column field="taskType" header="TASK TYPE" sortable />
                <DataTable.Column field="source" header="SOURCE" sortable />
                <DataTable.Column field="destination" header="DESTINATION" sortable />
                <DataTable.Column field="AssignedTo" header="ASSIGNED TO" sortable />
                <DataTable.Column field="completeDateTime" header="COMPLETED DATE/TIME" sortable />
                <DataTable.Column field="priority" header="PRIORITY" sortable />
                <DataTable.Column field="status" header="STATUS" sortable />
                <DataTable.Column
                    field="plane"
                    header="ACTIONS"
                    renderer={actionButtons}
                />
            </DataTable>
            <Modal closeIcon onClose={() => setEditModalComplete(false)} open={isEditModalComplete}>
                <CompleteEditModal />
            </Modal>
        </>
    );
};

export default CompletedTab;
