/* eslint-disable @typescript-eslint/ban-types */
/* eslint linebreak-style: ["error", "windows"] */
import React, { useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Modal,
} from '@scuf/common';
import PageLayoutWorkflow from '../../components/PageLayoutWorkflow';
import { dataPatientWorkflow } from '../../constanFile.js';
import AddDeviceModal from '../../components/Modals/AddModalDevice';

function Ophthalmology() {
    const [isAddDevice, setAddDevice] = useState(false);
    return (
        <div>
            <PageLayoutWorkflow data-testid="Ophthalmology" title="Ophthalmology">
                <div>
                    <DataTable data={dataPatientWorkflow} search searchPlaceholder="Search Records">
                        <DataTable.Column field="UHID" header="UHID" sortable />
                        <DataTable.Column field="name" header="Name" sortable />
                        <DataTable.Column field="testType" header="Test Type" sortable />
                        <DataTable.Column field="testDate" header="Test Date" sortable />
                        <DataTable.Column field="fromToLocation" header="From-To Location" sortable />
                        <DataTable.Column field="zone" header="Workflow" sortable />
                    </DataTable>
                </div>
                <Modal className="modal40" size="mini" closeIcon onClose={() => setAddDevice(false)} open={isAddDevice}>
                    <AddDeviceModal />
                </Modal>
            </PageLayoutWorkflow>

        </div>
    );
}
export default Ophthalmology;
