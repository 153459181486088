/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import { Icon, Button } from '@scuf/common';
import { getAllAssignedCalls } from '../../../__mocks__/API/taskAPI';
import { IGDA } from '../../../models/IGDA';

const AssignedTab = () => {
    const [allAssignedCalls, setAllAssignedCalls] = useState([] as object[]);

    useEffect(() => {
        getAllAssignedCalls().then((data) => {
            setAllAssignedCalls(data);
        });
    }, []);

    const actionButtons = () => (
        <Button
            type="link"
            icon={<Icon name="badge-important" size="small" root="common" color="blue" />}
            onClick={() => {}}
            content=""
        />
    );

    return (
        <>
            <DataTable data={allAssignedCalls} search searchPlaceholder="Search Records">
                <DataTable.Column field="uuid" header="TASK ID" sortable />
                <DataTable.Column field="taskType" header="TASK TYPE" sortable />
                <DataTable.Column field="source" header="SOURCE" sortable />
                <DataTable.Column field="destination" header="DESTINATION" sortable />
                <DataTable.Column
                    field="assignedGDAs"
                    header="ASSIGNED TO"
                    sortable
                    renderer={(props) => <div>{props.rowData.assignedGDAs.map((gda: IGDA) => <div>{gda.name}</div>)}</div>}
                />
                <DataTable.Column field="createdOn" header="ASSIGNED DATE/TIME" sortable />
                <DataTable.Column field="priority" header="PRIORITY" sortable />
                <DataTable.Column field="status" header="STATUS" sortable />
                <DataTable.Column
                    field="plane"
                    header="ACTIONS"
                    renderer={actionButtons}
                />
            </DataTable>
        </>
    );
};

export default AssignedTab;
