/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint linebreak-style: ["error", "windows"] */
import React, { useState } from 'react';
import {
    Button, Modal, Input,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';

import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';

interface Props extends IModalProps, IAuthContextProps {}
const EditBleHardware = ({
    open, onClose, ...others
}: Props) => {
    const [isBleName, setBleName] = useState('');
    const [isVersion, setVersion] = useState('');
    const [isSingleStrength, setSingleStrength] = useState('');
    const [isBattery, setBattery] = useState('');

    const isSubmitEnabled = () => isBleName?.length && isVersion?.length
    && isSingleStrength?.length && isBattery?.length;
    return (
        <Modal
            data-testid="EditBleHardwareModal"
            className="modal500"
            size="large"
            closeIcon
            onClose={onClose}
            open={open}
            {...others}
        >
            <div>
                <h2>Edit BLE hardware gateway</h2>
                <form>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Name"
                                label="Name"
                                className="w15"
                                onChange={setBleName}
                                value={isBleName}
                            />
                        </span>
                        <span className="m10">
                            <Input
                                placeholder="Version of BLE "
                                className="w15"
                                label="Version of BLE"
                                onChange={setVersion}
                                value={isVersion}
                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Signal strength"
                                className="w15"
                                label="Signal strength"
                                onChange={setSingleStrength}
                                value={isSingleStrength}
                            />

                        </span>
                        <span className="m10">
                            <Input
                                placeholder="Battery"
                                className="w15"
                                label="Battery"
                                onChange={setBattery}
                                value={isBattery}
                            />
                        </span>
                    </div>

                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary" actionType="submit">Cancel</Button>
                        </span>
                        <span>
                            <Button type="primary" disabled={!isSubmitEnabled()} actionType="submit">Confirm</Button>
                        </span>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default withAuthContext(EditBleHardware);
