import React, { ReactElement, ReactNode } from 'react';
import './GDAStyle.css';
import spacing from '../helpers/styles/spacing';

interface Props {
  children: ReactNode;
  title: string;
  headerRight?: ReactNode
}

const PageLayoutWorkflow = ({
    children, title, headerRight,
}: Props): ReactElement => (
    <div className="PageLayoutWorkflow">

        <div
            style={{
                margin: `${spacing.md}rem`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'right',
            }}
        >

            <h1
                style={{
                    margin: 0,
                }}
            >
                {title}
            </h1>
            {
                headerRight
            }
        </div>
        <div
            style={{
                padding: `0 ${spacing.md}rem`,
            }}
        >
            {children}
        </div>
    </div>
);

export default PageLayoutWorkflow;
