/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import '@scuf/common/honeywell-compact-combined/theme.css';
import '@scuf/datatable/honeywell-compact-combined/theme.css';
import './index.scss';
import AuthProvider from './helpers/Auth/AuthProvider';
ReactDOM.render(
    <AuthProvider>
        <Router>
            <App />
        </Router>
    </AuthProvider>,
     document.getElementById('root') as HTMLElement,
);
