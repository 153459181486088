import React, { ReactElement, useState } from 'react';
import {
    Icon, Tab, Button,
} from '@scuf/common';
import PageLayout from '../../components/PageLayout';
import CreateCall from '../../components/Modals/CreateCall';
import AddGDAModal from '../../components/Modals/AddGDAModal';
import AssignedTab from './partials/AssignedTab';
import CompletedTab from './partials/CompletedTab';
import ManageGDA from './partials/ManageGDA';
import { labTabs } from '../../constanFile.js';

const LabDashboard = (): ReactElement => {
    const [isCreateCallOpen, setCreateCallOpen] = useState(false);
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const headerRightButton = () => {
        switch (selectedTab) {
            case 2:
                return (
                    <Button
                        type="primary"
                        data-testid="creatGda"
                        content="ADD GDA"
                        onClick={() => setAddModalGda(true)}
                        icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
                        iconPosition="left"
                    />
                );
            default:
                return (
                    <Button
                        type="primary"
                        content="CREATE CALL"
                        onClick={() => setCreateCallOpen(true)}
                        icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
                        iconPosition="left"
                    />
                );
        }
    };

    return (
        <PageLayout title="Lab Dashboard" headerRight={headerRightButton()}>
            <>
                <Tab defaultActiveIndex={0} onTabChange={(currentTab) => setSelectedTab(currentTab)}>
                    <Tab.Pane title={labTabs[0]}>
                        <AssignedTab />
                    </Tab.Pane>
                    <Tab.Pane title={labTabs[1]}>
                        <CompletedTab />
                    </Tab.Pane>
                    <Tab.Pane title={labTabs[2]}>
                        <ManageGDA />
                    </Tab.Pane>
                </Tab>
                {isCreateCallOpen ? <CreateCall open={isCreateCallOpen} onClose={() => setCreateCallOpen(false)} /> : null}
                {isAddModalOpenGda ? <AddGDAModal open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} /> : null}

            </>
        </PageLayout>
    );
};
export default LabDashboard;
