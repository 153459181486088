/* eslint linebreak-style: ["error", "windows"] */
// eslint-disable-next-line
import React, { ReactElement, useState } from 'react';
import {
    Button, Select, Icon, Breadcrumb,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import { patientTypeDetails } from '../../constanFile.js';
const patientOptions = [
    { value: 'Aron Davis', text: 'Aron Davis' },
    { value: 'Jessica warren', text: 'Jessica warren' },
    { value: 'Tony Frank', text: 'Tony Frank' },
];
const testOptions = [{ value: 'MRI', text: 'MRI' }, { value: 'X-Ray', text: 'X-Ray' }];
const headerRightButton = () => (
    <Button
        type="link"
        content="Export"
        icon={(
            <Icon
                root="common"
                name="file-download"
                color="black"
                size="medium"
                className="reportExportIcon"
            />
        )}
        iconPosition="left"
        className="btnMedium"

    />
);
const PatientReport = (): ReactElement => (
    <>
        <Breadcrumb>
            <Breadcrumb.Item onClick={() => alert('Home')}>
                Report
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Patient Report
            </Breadcrumb.Item>
        </Breadcrumb>
        <PageLayout title="Patient" headerRight={headerRightButton()}>
            <form>
                <div className="mb10">
                    <span className="mr10">
                        <Select
                            placeholder="Select Patient"
                            options={patientOptions}
                            name="patientName"
                            className="w16"
                        />
                    </span>
                    <span className="mr10">
                        <Select
                            placeholder="Select Test"
                            options={testOptions}
                            name="testName"
                            className="w16"
                            required="required"
                        />
                    </span>

                    <span className="mr10">
                        <Button type="primary" actionType="submit">Show Data</Button>
                    </span>
                </div>
            </form>

            <DataTable
                data={patientTypeDetails}
                search
                searchPlaceholder="Search Records"
                rows={10}
            >
                <DataTable.Column field="UHID" header="UHID" sortable />
                <DataTable.Column field="name" header="Patient Name" sortable />
                <DataTable.Column field="testType" header="Test Type" sortable />
                <DataTable.Column field="testDate" header="Test Date" sortable />
                <DataTable.Column field="fromTo" header="From-To Location" sortable />
            </DataTable>
        </PageLayout>
    </>
);
export default PatientReport;
