import React, { ReactElement, ReactNode } from 'react';
import spacing from '../helpers/styles/spacing';
// import { Breadcrumb, Icon } from '@scuf/common';
// import { useHistory } from 'react-router-dom';

// import { colors } from '../assets/styles';

// interface IBreadcrumb {
//   title: string;
//   iconName?: string;
// }

interface Props {
  children: ReactNode;
  title: string;
  headerRight?: ReactNode
}

const PageLayout = ({
    children, title, headerRight,
}: Props): ReactElement => (
    <div
        style={{
            width: '100%',
        }}
    >
        <div
            style={{
                margin: `${spacing.md}rem`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <h1
                style={{
                    margin: 0,
                }}
            >
                {title}
            </h1>
            {
                headerRight
            }
        </div>
        <div
            style={{
                padding: `0 ${spacing.md}rem`,
            }}
        >
            {children}
        </div>
    </div>
);

export default PageLayout;
