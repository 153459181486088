/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    VerticalMenu,
} from '@scuf/common';
import '../../components/GDAStyle.css';
import TotalTask from './totalTask';
import PendingTask from './pendingTask';
import CompleteTask from './completeTask';

const nurseWorkflow = (): ReactElement => {
    const [selectedMenuIndex, setMenuIndex] = useState(0);
    return (
        <div style={{ marginLeft: 10 }}>
            <div className="systemPreference">
                <h3>Nurse Workflow</h3>
                <VerticalMenu>
                    <VerticalMenu.Header> My Stats </VerticalMenu.Header>

                    {(() => {
                        if (selectedMenuIndex === 0) {
                            return (
                                <VerticalMenu.Item
                                    active
                                    onClick={() => setMenuIndex(0)}
                                >
                                    Total Task
                                </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(0)}> Total Task  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 1) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(1)}>  Task Pending  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(1)}> Task Pending  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 2) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(2)}>  Complete Task  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(2)}>  Complete Task  </VerticalMenu.Item>
                        );
                    })()}
                </VerticalMenu>
            </div>
            {(() => {
                switch (selectedMenuIndex) {
                    case 1: return <PendingTask />;
                    case 2: return <CompleteTask />;
                    default: return <TotalTask />;
                }
            })()}
        </div>
    );
};
export default nurseWorkflow;
