/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AuthContext } from './AuthProvider';

const withAuthContext = (Component: React.FC<any>) => (props: any) => (
    <AuthContext.Consumer>
        { (context) => <Component {...props} {...context} /> }
    </AuthContext.Consumer>
);

export default withAuthContext;
