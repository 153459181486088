import React, { useState } from 'react';
import {
    Button, Input,
} from '@scuf/common';
import { addDeviceApi } from '../../__mocks__/API/addDeviceAPI';

function AddModalDevice() {
    const [bleName, setBleName] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const [password, setPassword] = useState('');
    const [zone, setZone] = useState('');
    const [ipAddress, setIpAddress] = useState('');

    const isSubmitEnabled = () => bleName?.length && macAddress?.length && password.length && zone.length;
    return (
        <div>
            <form data-testid="deviceModal" style={{ alignContent: 'center' }}>
                <h1>Add Device</h1>
                <div>
                    <div>
                        <span>
                            <Input
                                name="bleName"
                                placeholder="BLE Name"
                                indicator="required"
                                label="BLE Name"
                                className="w20"
                                onChange={setBleName}
                                value={bleName}
                            />
                        </span>
                    </div>
                    <div>
                        <span>
                            <Input
                                name="macAddress"
                                placeholder="MAC Address"
                                indicator="required"
                                className="w20"
                                label="MAC Address"
                                onChange={setMacAddress}
                                value={macAddress}
                            />
                        </span>
                    </div>
                    <div>
                        <span>
                            <Input
                                type="password"
                                indicator="required"
                                placeholder="Password"
                                className="w20"
                                label="Password"
                                onChange={setPassword}
                                value={password}
                            />
                        </span>
                    </div>
                    <div>
                        <span>
                            <Input
                                placeholder="IP Address"
                                className="w20"
                                indicator="required"
                                label="IP Address"
                                onChange={setIpAddress}
                                value={ipAddress}
                            />
                        </span>
                    </div>
                    <div>
                        <span>
                            <Input
                                placeholder="Zone Group"
                                className="w20"
                                indicator="required"
                                label="Zone Group"
                                onChange={setZone}
                                value={zone}
                            />
                        </span>
                    </div>
                </div>
                <div className="divConfirmCancel">
                    <span className="m10">
                        <Button type="secondary" actionType="reset">Reset</Button>
                    </span>
                    <span className="m10">

                        <Button
                            onClick={() => addDeviceApi({
                                bleName,
                                macAddress,
                                password,
                                ipAddress,
                                zone,
                            })}
                            type="primary"
                            actionType="button"
                            disabled={!isSubmitEnabled()}
                        >
                            Confirm
                        </Button>
                    </span>
                </div>
            </form>

        </div>
    );
}
export default AddModalDevice;
