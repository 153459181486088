/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    VerticalMenu,
} from '@scuf/common';

import '../../components/GDAStyle.css';
import Infrastructure from './Infrastructure';
import BLE from './BLE';
import Lab from './Lab';

const BLEDashboard = (): ReactElement => {
    const [selectedMenuIndex, setMenuIndex] = useState(0);
    return (
        <div style={{ marginLeft: 10 }}>
            <div className="systemPreference">
                <h3>System Preference</h3>
                <VerticalMenu>
                    <VerticalMenu.Header> Manage </VerticalMenu.Header>

                    {(() => {
                        if (selectedMenuIndex === 0) {
                            return (
                                <VerticalMenu.Item
                                    active
                                    onClick={() => setMenuIndex(0)}
                                >
                                    BLE Gateways
                                </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(0)}> BLE Gateways  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 1) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(1)}> Infrastructure  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(1)}> Infrastructure  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 2) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(2)}> Laboratory Tests  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(2)}> Laboratory Tests  </VerticalMenu.Item>
                        );
                    })()}

                </VerticalMenu>
            </div>
            {(() => {
                switch (selectedMenuIndex) {
                    case 1: return <Infrastructure />;

                    case 2: return <Lab />;

                    default: return <BLE />;
                }
            })()}
        </div>
    );
};
export default BLEDashboard;
