/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
    Button, Select, Modal,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';

import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';
import {
    gdaAssignTo, taskType, departmentList,
} from '../../constanFile.js';

interface Props extends IModalProps, IAuthContextProps {}
const AddGDAModal = ({
    open, onClose, ...others
}: Props) => {
    const [isTag, setTag] = useState('');
    const [isgdaName, setGdaName] = useState('');
    const [isDepartment, setDepartment] = useState('');
    const isSubmitEnabled = () => isgdaName?.length && isTag?.length && isDepartment?.length;
    return (
        <Modal
            data-testid="createGdaModal"
            className="modal500"
            size="small"
            closeIcon
            onClose={onClose}
            open={open}
            {...others}
        >
            <div>
                <h2>Add GDA</h2>
                <form>
                    <div style={{ margin: '0 10%' }}>
                        <Select
                            search
                            label="GDA"
                            indicator="required"
                            placeholder="Search GDA by ID"
                            options={gdaAssignTo}
                            onChange={setGdaName}
                            value={isgdaName}
                        />
                        <div className="m10">
                            <Select
                                search
                                label="Tag No."
                                indicator="required"
                                placeholder="Select"
                                options={taskType}
                                onChange={setTag}
                                value={isTag}
                            />
                        </div>
                        <div className="m10">
                            <Select
                                search
                                label="Department"
                                indicator="required"
                                placeholder="Select"
                                options={departmentList}
                                onChange={setDepartment}
                                value={isDepartment}
                            />
                        </div>
                    </div>
                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary" actionType="submit">Cancel</Button>
                        </span>
                        <span>
                            <Button type="primary" disabled={!isSubmitEnabled()} actionType="submit">Confirm</Button>
                        </span>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default withAuthContext(AddGDAModal);
