/* eslint linebreak-style: ["error", "windows"] */
// eslint-disable-next-line
import React, { ReactElement, useState } from 'react';
import {
    Icon, Button, Grid, Card, Divider,
} from '@scuf/common';
import { useHistory } from 'react-router-dom';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import AddTestType from '../../components/Modals/AddTestType';
import EditTestType from '../../components/Modals/EditTestType';
import AddScheduleTest from '../../components/Modals/AddScheduleTest';
import { patientTypeDetails } from '../../constanFile.js';

const DoctorDashboard = (): ReactElement => {
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [isEditModalComplete, setEditModalComplete] = useState(false);
    const [isAddModalTest, setAddModalTest] = useState(false);
    const history = useHistory();
    const routeChange = () => {
        const path = 'assign-test';
        history.push(path);
    };

    const actionButtons = () => (
        <Button
            onClick={() => setEditModalComplete(true)}
            type="link"
            icon={<Icon name="clipboard" size="small" root="common" color="blue" />}
            content=""
        />
    );

    const testButtons = () => (
        <div>
            <Button
                onClick={() => setAddModalTest(true)}
                type="link"
                icon={<Icon name="clockwise" size="small" root="common" color="blue" />}
                content=""
            />

            <Button
                onClick={routeChange}
                type="link"
                icon={<Icon name="entities-list" size="small" root="common" color="blue" />}
                content=""
            />
        </div>
    );

    return (
        <PageLayout title="">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard red">Today Register </p>
                                    <p className="ml20">
                                        <span> IPD</span>
                                        <span className="ml127"> 100 </span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>

                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard yellow">Total Patient </p>
                                    <p className="ml20">
                                        <span>IPD</span>
                                        <span className="ml127"> 100 </span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span> OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard blue">Total Patient </p>
                                    <p className="ml20">
                                        <span>IPD</span>
                                        <span className="ml127">100</span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard green">Total Patient </p>
                                    <p className="ml20">
                                        <span>IPD</span>
                                        <span className="ml127"> 100</span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <>
                <DataTable
                    data={patientTypeDetails}
                    search
                    searchPlaceholder="Search Records"
                    rows={10}
                >
                    <DataTable.Column field="UHID" header="UHID" sortable />
                    <DataTable.Column field="name" header="Patient Name" sortable />
                    <DataTable.Column field="testType" header="Test Type" sortable />
                    <DataTable.Column field="testDate" header="Test Date" sortable />
                    <DataTable.Column field="fromTo" header="From-To Location" sortable />
                    <DataTable.Column
                        field="plane"
                        header="Workflow"
                        renderer={actionButtons}
                    />
                    <DataTable.Column
                        field="plane"
                        header="Schedule Test"
                        renderer={testButtons}
                    />
                </DataTable>
                <AddTestType open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} />
                <EditTestType open={isEditModalComplete} onClose={() => setEditModalComplete(false)} />
                <AddScheduleTest open={isAddModalTest} onClose={() => setAddModalTest(false)} />

            </>
        </PageLayout>
    );
};
export default DoctorDashboard;
