import React, { useState } from 'react';
import {
    Card, Input, Checkbox, Button,
} from '@scuf/common';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import spacing from '../helpers/styles/spacing';
import withAuthContext from '../helpers/Auth/withAuth';
import { IAuthContextProps } from '../models/IAuthModel';

interface Props extends RouteComponentProps, IAuthContextProps {
}

const Login: React.FC<Props> = ({ history, auth, signIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const onSubmit = () => {
        if (username && password) {
            signIn(username, password);
        }
    };

    if (auth.isAuthenticated) {
        const currentAddress = history.location.pathname;
        history.push(currentAddress);
    }

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    width: 'fit-content',
                    margin: 'auto',
                    marginTop: `${spacing.Four_xl + spacing.Four_xl + spacing.sm}rem`,
                }}
            >
                <Card>
                    <div
                        style={{
                            padding: `${spacing.Two_xl + spacing.md}rem ${spacing.Four_xl + spacing.md}rem`,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            src="https://www.honeywell.com/content/dam/honeywellbt/en/images/logos/impact-by-honeywell-logo.png"
                            alt="logo"
                            style={{ width: '218px', margin: 'auto', marginBottom: `${spacing.Four_xl}rem` }}
                        />
                        <div style={{ marginBottom: `${spacing.lg + spacing.lg}rem` }}>
                            <Input
                                onChange={setUsername}
                                value={username}
                                placeholder="hello@honeywell.com"
                                label="Email"
                                style={{ width: '368px' }}
                            />
                        </div>
                        <div
                            style={{ marginBottom: `${spacing.lg + spacing.md}rem` }}
                        >
                            <Input
                                onChange={setPassword}
                                value={password}
                                type="password"
                                label="Password"
                                style={{ width: '368px' }}
                            />
                        </div>
                        <div style={{ marginBottom: `${spacing.md}rem` }}>
                            <Checkbox
                                onChange={setRememberMe}
                                label="Remember Me"
                                checked={rememberMe}
                            />
                        </div>
                        <Button
                            onClick={() => onSubmit()}
                            size="medium"
                            type="primary"
                        >
                            Sign In
                        </Button>
                        <div style={{ marginTop: `${spacing.Two_xl + spacing.md}rem`, textAlign: 'right' }}>
                            <Button
                                size="medium"
                                type="link"
                                onClick={() => { window.location.href = '/forgotPassword'; }}

                            >
                                Forgot Password
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default withRouter(withAuthContext(Login));
