import React, { ReactElement } from 'react';
import { Header as ScufHeader } from '@scuf/common';
import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';

interface Props extends IAuthContextProps {
  onMenuToggle: (() => void);
  onHeaderTransition: ((data: {
    compressed: boolean;
    collapsed: boolean;
  }) => void)
}

const Header = ({
    onMenuToggle, onHeaderTransition, signOut,
}: Props): ReactElement => (
    <ScufHeader
        onMenuToggle={onMenuToggle}
        onHeaderTransition={onHeaderTransition}
        title="IHP Safe Steps"
        logoUrl="https://www.honeywell.com/content/dam/honeywellbt/en/images/logos/impact-by-honeywell-logo.png"
    >

        <ScufHeader.NotificationCenter
            onSeeAllClick={() => { window.location.href = '/notification'; }}
            totalNumber={7}
        >
            <ScufHeader.NotificationCenter.Item
                content="Patient Test Complete"
                title="Nisha Arrived "
                datetime="1 hr ago"
            />
            <ScufHeader.NotificationCenter.Item
                title="Sonam Patil"
                datetime="Yesterday"
                content="Test Assign To Patient"
            />
            <ScufHeader.NotificationCenter.Item
                severity="important"
                title="Varun Dhavan"
                datetime="12/31/1999"
                content="Patient In"
                onClick={() => alert('clicked notification 3')}
            />

        </ScufHeader.NotificationCenter>
        <ScufHeader.UserProfile firstName="Rocket" lastName="Racoon" role="Demolitions">
            <ScufHeader.UserProfile.Item onClick={() => { window.location.href = '/profile'; }}>Profile</ScufHeader.UserProfile.Item>
            <ScufHeader.UserProfile.Item onClick={() => signOut()}>Log Out</ScufHeader.UserProfile.Item>
        </ScufHeader.UserProfile>
    </ScufHeader>
);

export default withAuthContext(Header);
