/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Icon, Tab, Modal, Button, Input, Radio, InputLabel, Divider,
} from '@scuf/common';
import dataBle from '../json/ble.json';
import dataTag from '../json/bleTag.json';

const BLERegistration = (): ReactElement => {
    const [isAddModalOpenGateway, setAddModalGateway] = useState(false);
    const [isAddModalOpenTag, setAddModalTag] = useState(false);
    const [contacts, setContacts] = useState(dataBle);
    const [addFormData, setAddFormData] = useState({
        bleName: '',
        macAddress: '',
        ssid: '',
        password: '',
        subscriberName: '',
        localIp: '',
        status: '',
    });

    const handleAddFormChange = (fieldName, fieldValue) => {
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };

    const handleAddFormSubmit = () => {
        const newContact = {
            bleName: addFormData.bleName,
            macAddress: addFormData.macAddress,
            ssid: addFormData.ssid,
            password: addFormData.password,
            subscriberName: addFormData.subscriberName,
            localIp: addFormData.localIp,
            status: addFormData.status,
        };
        const newContacts = [...contacts, newContact];
        setContacts(newContacts);

        setAddModalGateway(false);
    };

    return (
        <div style={{ marginLeft: 10 }}>
            <Tab defaultActiveIndex={0}>
                <Tab.Pane title="Gateway">
                    <div>
                        <div className="addBtnHead">
                            <Button type="primary" icon={<Icon name="slidercontrols-plus" size="small" root="common" />} content="Ble Gateway Register" onClick={() => setAddModalGateway(true)} />
                        </div>
                        <DataTable data={contacts} search searchPlaceholder="Search Records">
                            <DataTable.Column field="bleName" header="Ble Name" sortable />
                            <DataTable.Column field="macAddress" header="MAC address" sortable />
                            <DataTable.Column field="ssid" header="SSID" sortable />
                            <DataTable.Column field="password" header="Password" sortable />
                            <DataTable.Column field="subscriberName" header="Subscriber name" sortable />
                            <DataTable.Column field="localIp" header="Local IP" sortable />
                            <DataTable.Column field="status" header="Status" sortable />
                        </DataTable>
                    </div>
                </Tab.Pane>
                <Tab.Pane title="Tag">
                    <div>
                        <div className="addBtnHead">
                            <Button type="primary" icon={<Icon name="slidercontrols-plus" size="small" root="common" />} content="Ble Tag Register" onClick={() => setAddModalTag(true)} />
                        </div>
                        <DataTable data={dataTag} search searchPlaceholder="Search Records">
                            <DataTable.Column field="bleName" header="Ble Name" sortable />
                            <DataTable.Column field="macAddress" header="MAC address" sortable />
                            <DataTable.Column field="bleTag" header="Ble Tag" sortable />
                            <DataTable.Column field="fixedMove" header="Fixed/Moving" sortable />
                            <DataTable.Column field="status" header="Status" sortable />
                        </DataTable>
                    </div>
                </Tab.Pane>
            </Tab>

            <Modal closeIcon onClose={() => setAddModalGateway(false)} open={isAddModalOpenGateway}>
                <form onSubmit={handleAddFormSubmit}>
                    <h1>Ble Gateway Registration</h1>
                    <Divider fitted />
                    <div>
                        <div>
                            <span style={{ margin: 10 }}>
                                <Input
                                    placeholder="Ble Name"
                                    label="Ble Name"
                                    name="bleName"
                                    onChange={(value) => (handleAddFormChange('bleName', value))}
                                />
                            </span>
                            <span style={{ margin: 10 }}>
                                <Input
                                    placeholder="MAC Address"
                                    label="MAC Address"
                                    name="macAddress"
                                    onChange={(value) => (handleAddFormChange('macAddress', value))}
                                />
                            </span>
                        </div>
                        <div>
                            <span style={{ margin: 10 }}>
                                <Input
                                    placeholder="SSID"
                                    label="SSID"
                                    name="ssid"
                                    onChange={(value) => (handleAddFormChange('ssid', value))}
                                />
                            </span>
                            <span style={{ margin: 10 }}>
                                <Input
                                    placeholder="Password"
                                    label="Password"
                                    type="password"
                                    name="password"
                                    onChange={(value) => (handleAddFormChange('password', value))}
                                />
                            </span>
                        </div>
                        <div>
                            <span style={{ margin: 10 }}>
                                <Input
                                    placeholder="Subscriber name"
                                    label="Subscriber name"
                                    name="subscriberName"
                                    onChange={(value) => (handleAddFormChange('subscriberName', value))}
                                />
                            </span>
                            <span style={{ margin: 10 }}>
                                <Input
                                    placeholder="Local IP"
                                    label="Local IP"
                                    name="localIp"
                                    onChange={(value) => (handleAddFormChange('localIp', value))}
                                />
                            </span>
                        </div>
                        <div className="statusDiv">
                            <InputLabel label="Status" />
                        </div>
                        <div style={{ width: 500 }}>
                            <Radio
                                label="Active"
                                name="status"
                                value="Active"
                                onChange={() => (handleAddFormChange('status', 'Active'))}
                            />
                            <Radio
                                label="In-active"
                                name="status"
                                value="In-active"
                                onChange={() => (handleAddFormChange('status', 'In-active'))}
                            />
                        </div>
                        <span style={{ margin: 10 }}>
                            <Button
                                type="primary"
                                actionType="submit"

                            >
                                Submit
                            </Button>
                        </span>
                    </div>
                </form>
            </Modal>
            <Modal closeIcon onClose={() => setAddModalTag(false)} open={isAddModalOpenTag}>
                <form style={{ alignContent: 'center' }}>
                    <h1>Ble Tag Registration</h1>
                    <Divider fitted />
                    <div>
                        <div>
                            <span style={{ margin: 10 }}>
                                <Input placeholder="Ble Name" label="Ble Name" />
                            </span>
                            <span style={{ margin: 10 }}>
                                <Input placeholder="MAC Address" label="MAC Address" />
                            </span>
                        </div>
                        <div style={{ width: 580 }}>
                            <span style={{ margin: 10 }}>
                                <Input placeholder="Ble Tag" label="Ble Tag" />
                            </span>
                            <span style={{ margin: 10 }}>
                                <div className="moving">
                                    <InputLabel label="Fixed/Moving" />
                                </div>
                                <div style={{ marginLeft: 375 }}>
                                    <Radio label="Fixed" name="fix_move" value="Fixed" />
                                    <Radio label="Moving" name="fix_move" value="Moving" />
                                </div>
                            </span>
                        </div>
                        <div className="statusDivTag">

                            <InputLabel label="Status" />
                        </div>
                        <div style={{ width: 500 }}>
                            <Radio label="Yes" name="status" value="Yes" />
                            <Radio label="No" name="status" value="No" />
                        </div>
                        <span style={{ margin: 10 }}>
                            <Button type="primary" actionType="submit">Submit</Button>
                        </span>
                    </div>
                </form>
            </Modal>
        </div>
    );
};
export default BLERegistration;
