/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    Icon, Tab, Button, Grid, Card,
} from '@scuf/common';
import PageLayout from '../../components/PageLayout';
import CreateCall from '../../components/Modals/CreateCall';
import AddGDAModal from '../../components/Modals/AddGDAModal';
import UnaasignedTab from './partials/UnaasignedTab';
import AssignedTab from './partials/AssignedTab';
import CompletedTab from './partials/CompletedTab';
import ManageGDA from './partials/ManageGDA';
import { tabs } from '../../constanFile.js';

const GDADashboard = (): ReactElement => {
    const [isCreateCallOpen, setCreateCallOpen] = useState(false);
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const headerRightButton = () => {
        switch (selectedTab) {
            case 3:
                return (
                    <Button
                        type="primary"
                        content="Add GDA"
                        onClick={() => setAddModalGda(true)}
                        icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
                        iconPosition="left"
                    />
                );
            default:
                return (
                    <Button
                        type="primary"
                        content="CREATE CALL"
                        onClick={() => setCreateCallOpen(true)}
                        icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
                        iconPosition="left"
                    />
                );
        }
    };

    return (
        <PageLayout title="GDA Dashboard" headerRight={headerRightButton()}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span><Icon root="common" name="user" size="large" /></span>
                                <span className="textRight">
                                    <p className="mtminus40">
                                        <span> 10</span>
                                    </p>
                                    <p>
                                        <span> Assign GDA</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>

                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span><Icon root="common" name="user" size="large" /></span>
                                <span className="textRight">
                                    <p className="mtminus40">
                                        <span> 5</span>
                                    </p>
                                    <p>
                                        <span> Free GDA</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>

                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span><Icon root="common" name="user" size="large" /></span>
                                <span className="textRight">
                                    <p className="mtminus40">
                                        <span> 5</span>
                                    </p>
                                    <p>
                                        <span> Free GDA</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span><Icon root="common" name="user" size="large" /></span>
                                <span className="textRight">
                                    <p className="mtminus40">
                                        <span> 5</span>
                                    </p>
                                    <p>
                                        <span> Free GDA</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <>
                <Tab defaultActiveIndex={0} onTabChange={(currentTab) => setSelectedTab(currentTab)}>
                    <Tab.Pane title={tabs[0]}>
                        <UnaasignedTab />
                    </Tab.Pane>
                    <Tab.Pane title={tabs[1]}>
                        <AssignedTab />
                    </Tab.Pane>
                    <Tab.Pane title={tabs[2]}>
                        <CompletedTab />
                    </Tab.Pane>
                    <Tab.Pane title={tabs[3]}>
                        <ManageGDA />
                    </Tab.Pane>
                </Tab>
                {isCreateCallOpen ? <CreateCall open={isCreateCallOpen} onClose={() => setCreateCallOpen(false)} /> : null}
                {isAddModalOpenGda ? <AddGDAModal open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} /> : null}

            </>
        </PageLayout>
    );
};
export default GDADashboard;
