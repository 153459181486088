/* eslint linebreak-style: ["error", "windows"] */
// eslint-disable-next-line
import React, { ReactElement, useState } from 'react';
import {
    Grid, Card, Divider, Badge,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import AddTestType from '../../components/Modals/AddTestType';
import EditTestType from '../../components/Modals/EditTestType';
import AddScheduleTest from '../../components/Modals/AddScheduleTest';
import { patientTypeDetails } from '../../constanFile.js';

const NurseDashboard = (): ReactElement => {
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [isEditModalComplete, setEditModalComplete] = useState(false);
    const [isAddModalTest, setAddModalTest] = useState(false);

    return (
        <PageLayout title="">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard red">Today Register </p>
                                    <p className="ml20">
                                        <span> IPD</span>
                                        <span className="ml127"> 100  </span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard yellow">Total Patient </p>
                                    <p className="ml20">
                                        <span>IPD</span>
                                        <span className="ml127">100 </span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard blue">Total Patient </p>
                                    <p className="ml20">
                                        <span>IPD</span>
                                        <span className="ml127">100</span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Content>
                                <span>
                                    <p className="headerCard green">Total Patient </p>
                                    <p className="ml20">
                                        <span>IPD</span>
                                        <span className="ml127"> 100</span>
                                    </p>
                                    <Divider />
                                    <p className="ml20">
                                        <span>OPD</span>
                                        <span className="ml120"> 200</span>
                                    </p>
                                </span>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Card>
                            <Card.Header className="bold" title="Department" />
                            <Divider fitted />
                            <Card.Content>
                                <p>
                                    <span>Cardiology</span>
                                    <span className="ml115">
                                        <Badge color="red"> 10 </Badge>
                                    </span>
                                </p>
                                <Divider fitted />
                                <p>
                                    <span>  Dermatology</span>
                                    <span className="ml105">
                                        <Badge color="yellow"> 5</Badge>
                                    </span>
                                </p>
                                <Divider fitted />
                                <p>
                                    <span>  Neurology</span>
                                    <span className="ml120">
                                        <Badge color="blue">7</Badge>
                                    </span>
                                </p>
                                <Divider fitted />
                                <p>
                                    <span>  Ophthalmology</span>
                                    <span className="ml85">
                                        <Badge color="green">2</Badge>
                                    </span>
                                </p>
                                <Divider fitted />
                                <p>
                                    <span>  Orthopedics</span>
                                    <span className="ml105">
                                        <Badge color="red">1</Badge>
                                    </span>
                                </p>
                                <Divider fitted />
                                <p>
                                    <span>  Inpatient Service (IP)</span>
                                    <span className="ml50">
                                        <Badge color="yellow">1</Badge>
                                    </span>
                                </p>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <DataTable
                            data={patientTypeDetails}
                            search
                            searchPlaceholder="Search Records"
                            rows={10}
                        >
                            <DataTable.Column field="UHID" header="UHID" sortable />
                            <DataTable.Column field="name" header="Patient Name" sortable />
                            <DataTable.Column field="testType" header="Test Type" sortable />
                            <DataTable.Column field="testDate" header="Test Date" sortable />
                            <DataTable.Column field="fromTo" header="From-To Location" sortable />

                        </DataTable>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <>

                <AddTestType open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} />
                <EditTestType open={isEditModalComplete} onClose={() => setEditModalComplete(false)} />
                <AddScheduleTest open={isAddModalTest} onClose={() => setAddModalTest(false)} />
            </>
        </PageLayout>
    );
};
export default NurseDashboard;
