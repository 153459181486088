/* eslint linebreak-style: ["error", "windows"] */
// eslint-disable-next-line
import React, { ReactElement, useState } from 'react';
import {
    Grid, Card, Divider, Badge, Breadcrumb,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { Chart } from '@scuf/charts';
import PageLayout from '../../components/PageLayout';
import AddTestType from '../../components/Modals/AddTestType';
import EditTestType from '../../components/Modals/EditTestType';
import AddScheduleTest from '../../components/Modals/AddScheduleTest';
import GDAPatientTask from '../../json/GDAPatientTask.json';

const GDAWorkflow = (): ReactElement => {
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [isEditModalComplete, setEditModalComplete] = useState(false);
    const [isAddModalTest, setAddModalTest] = useState(false);
    const [contacts] = useState(GDAPatientTask);

    const statusRenderer = (cellData) => {
        const data = cellData.value;
        const color = data === 'Complete' ? 'blue' : 'red';
        return (
            <span>
                <Badge color={color}>{data}</Badge>
            </span>
        );
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => alert('Home')}>
                    Workflow
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    GDA Workflow
                </Breadcrumb.Item>
            </Breadcrumb>
            <PageLayout title="">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Card>
                                <Card.Content>
                                    <span>
                                        <p className="headerCard red">Patient Transfered </p>
                                        <p className="ml10">
                                            <span> Total</span>
                                            <span className="ml85">  Last Hour</span>
                                        </p>
                                        <Divider />
                                        <p className="ml10">
                                            <span> 100</span>
                                            <span className="ml105"> 200</span>
                                        </p>
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Card>
                                <Card.Content>
                                    <span>
                                        <p className="headerCard yellow">Avg. Wait Time </p>
                                        <p className="ml10">
                                            <span>LAB(MIN)</span>
                                            <span className="ml30"> IPD Ward (MIN)</span>
                                        </p>
                                        <Divider />
                                        <p className="ml10">
                                            <span> 100</span>
                                            <span className="ml105"> 200</span>
                                        </p>
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Card>
                                <Card.Content>
                                    <span>
                                        <p className="headerCard blue">Task </p>
                                        <p className="ml10">
                                            <span>Completed</span>
                                            <span className="ml70">Pending</span>
                                        </p>
                                        <Divider />
                                        <p className="ml20">
                                            <span> 100</span>
                                            <span className="ml115"> 200</span>
                                        </p>
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Card>
                                <Card.Content>
                                    <span>
                                        <p className="headerCard green">GDA </p>
                                        <p className="ml10">
                                            <span>Assigned</span>
                                            <span className="ml85">Free</span>
                                        </p>
                                        <Divider />
                                        <p className="ml10">
                                            <span> 100</span>
                                            <span className="ml120"> 200</span>
                                        </p>
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Card>
                                <Card.Header className="bold" title="Date Wise Patient Transfered" />
                                <Divider fitted />
                                <Card.Content>
                                    <p>
                                        <span>11-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="red"> 10 </Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>10-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="yellow"> 5</Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>09-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="blue">7</Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>08-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="green">2</Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>07-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="red">1</Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>06-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="yellow">1</Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>05-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="blue">1</Badge>
                                        </span>
                                    </p>
                                    <Divider fitted />
                                    <p>
                                        <span>04-11-2021</span>
                                        <span className="ml190">
                                            <Badge color="green">1</Badge>
                                        </span>
                                    </p>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Card>
                                <Card.Header title="" />
                                <Card.Content>
                                    <Chart title="GDA Task History">
                                        <Chart.Column
                                            name="Patient Transfer"
                                            data={[
                                                1,
                                                2,
                                                2,
                                                1,
                                                6,
                                                5,
                                                9,
                                                4,
                                            ]}
                                        />
                                        <Chart.XAxis
                                            categories={[
                                                '11-11-2021',
                                                '10-11-2021',
                                                '09-11-2021',
                                                '08-11-2021',
                                                '07-11-2021',
                                                '06-11-2021',
                                                '05-11-2021',
                                                '04-11-2021',
                                            ]}
                                        />
                                    </Chart>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <DataTable
                                data={contacts}
                                search
                                searchPlaceholder="Search Records"
                                rows={10}
                            >
                                <DataTable.Column field="UHID" header="UHID" sortable />
                                <DataTable.Column field="patientName" header="Patient Name" sortable />
                                <DataTable.Column field="pickupTime" header="Pickup Time" sortable />
                                <DataTable.Column field="duration" header="Duration" sortable />
                                <DataTable.Column field="dropTime" header="Drop Time" sortable />
                                <DataTable.Column field="status" header="Status" sortable renderer={statusRenderer} />

                            </DataTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <>

                    <AddTestType open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} />
                    <EditTestType open={isEditModalComplete} onClose={() => setEditModalComplete(false)} />
                    <AddScheduleTest open={isAddModalTest} onClose={() => setAddModalTest(false)} />
                </>
            </PageLayout>
        </>
    );
};
export default GDAWorkflow;
