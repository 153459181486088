/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import { Button } from '@scuf/common';
import CallDetailsAssignGDAModal from '../../../components/Modals/CallDetailsAssignGDAModal';
import { getAllUnassignedCalls } from '../../../__mocks__/API/taskAPI';
import { ICallDetails } from '../../../models/ICall';

function timeDiffCalc(dateFuture: Date, dateNow: Date) {
    let diffInMilliSeconds = Math.abs(dateNow.getTime() - dateFuture.getTime()) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    const seconds = Math.floor(diffInMilliSeconds) % 60;

    if (days > 0) {
        return days > 1 ? `${days} days` : `${days} day`;
    } if (hours > 0) {
        const hourText = (hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
        const minuteText = (minutes === 1) ? `${minutes} minute` : `${minutes} minutes`;
        return `${hourText}${minuteText}`;
    } if (minutes > 0) {
        const minuteText = (minutes === 1) ? `${minutes} minute` : `${minutes} minutes`;
        return minuteText;
    }
    return `${seconds} seconds`;
}

const ElapsedTime = ({ rowData }: {rowData: any}) => {
    const [timeString, setTimeString] = useState('');

    useEffect(() => {
        const createdTime = new Date(rowData.createdOn);
        setTimeout(() => {
            const diff = timeDiffCalc(createdTime, new Date());
            setTimeString(diff);
        }, 1000);
    });

    return <>{timeString}</>;
};

const UnaasignedTab = () => {
    const [isEditModalOpenUnassign, setEditModalUnassign] = useState(false);
    const [allUnassignedCalls, setUnassignedCalls] = useState<ICallDetails[]>([]);
    const [error, setError] = useState();

    const getTableData = async () => {
        try {
            const allUnassignedCalls = await getAllUnassignedCalls();
            setUnassignedCalls(allUnassignedCalls);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        getTableData();
    }, []);

    const assignGDAButton = () => (
        <Button
            type="link"
            onClick={() => setEditModalUnassign(true)}
            content="ASSIGN GDA"
        />
    );

    if (error) {
        return <>error</>;
    }

    return (
        <>
            <DataTable data={allUnassignedCalls} search searchPlaceholder="Search Records">
                <DataTable.Column field="uuid" header="TASK ID" sortable />
                <DataTable.Column field="taskType" header="TASK TYPE" sortable />
                <DataTable.Column field="createdOn" header="CALL DATE/TIME" sortable />
                <DataTable.Column field="source" header="SOURCE" sortable />
                <DataTable.Column field="destination" header="DESTINATION" sortable />
                <DataTable.Column
                    field="callBy"
                    header="CALL BY"
                    sortable
                    renderer={({ rowData }) => <>{rowData.callBy.id}</>}
                />
                <DataTable.Column field="priority" header="PRIORITY" sortable />
                <DataTable.Column
                    field="timeElapsed"
                    header="TIME ELAPSED"
                    sortable
                    renderer={({ rowData }) => <ElapsedTime rowData={rowData} />}
                />
                <DataTable.Column
                    field="plane"
                    header="ACTIONS"
                    renderer={assignGDAButton}
                />
            </DataTable>
            <CallDetailsAssignGDAModal
                onClose={() => setEditModalUnassign(false)}
                open={isEditModalOpenUnassign}
                modalTitle="Assign GDA"
                reOpen={() => setEditModalUnassign(true)}
            />
        </>
    );
};

export default UnaasignedTab;
