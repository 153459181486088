/* eslint linebreak-style: ["error", "windows"] */
import React, { useState, useEffect } from 'react';
import ImageMarker, { Marker } from 'react-image-marker';
import { Card, Popup, Search } from '@scuf/common';
import axios from 'axios';
import BuildingMap from './images/map_main_building.png';
import walkingMan from './images/walking_man.gif';
import { dataPatient } from '../constanFile.js';

function Tracking() {
    const [isSearch, searchTracking] = useState();

    const [markers] = useState<Array<Marker>>([
        { top: 20, left: 60 },
    ]);

    useEffect(() => {
        axios
            .get('https://jsonplaceholder.typicode.com/users')
            .then((res) => {
                searchTracking(res.data.name);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const CustomMarker = () => (
        <div>
            <Popup data={dataPatient} className="popup-theme-wrap" element={<img src={walkingMan} alt="map" width="35" />} position="right center" hideOnScroll>

                <Card>
                    <Card.Content>
                        <p> Patien Name :ABC </p>
                        <p>Test : MRI</p>
                        <p>Destination : MRI</p>
                    </Card.Content>
                </Card>

            </Popup>
        </div>
    );
    return (
        <div className="App">

            <Search
                results={searchTracking}
                value={isSearch}
                placeholder="Search Patient"
            />
            <div className="frame">
                <ImageMarker
                    src={BuildingMap}
                    markers={markers}
                    markerComponent={CustomMarker}
                />
            </div>
        </div>
    );
}

export default Tracking;
