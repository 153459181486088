/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    Icon, Button,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import AddBleHardware from '../../components/Modals/AddBleHardware';
import EditBleHardware from '../../components/Modals/EditBleHardware';
import { bleHardwareDetails } from '../../constanFile.js';

const BLEHardware = (): ReactElement => {
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [isEditModalComplete, setEditModalComplete] = useState(false);

    const headerRightButton = () => (
        <Button
            type="primary"
            content="Add BLE hardware gateway"
            onClick={() => setAddModalGda(true)}
            icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
            iconPosition="left"
        />
    );
    const actionButtons = () => (
        <Button
            onClick={() => setEditModalComplete(true)}
            type="link"
            icon={<Icon name="edit" size="small" root="common" color="blue" />}
            content=""
        />
    );

    return (
        <PageLayout title="BLE hardware gateway" headerRight={headerRightButton()}>
            <>
                <DataTable
                    data={bleHardwareDetails}
                    search
                    searchPlaceholder="Search Records"
                    rows={10}
                >
                    <DataTable.Column field="name" header="Hardware Name" sortable />
                    <DataTable.Column field="version" header="Version of BLE" sortable />
                    <DataTable.Column field="singleStrength" header="Signal strength" sortable />
                    <DataTable.Column field="battery" header="Battery" sortable />
                    <DataTable.Column field="status" header="Status" sortable />
                    <DataTable.Column
                        field="plane"
                        header="ACTIONS"
                        renderer={actionButtons}
                    />
                </DataTable>
                <AddBleHardware open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} />
                <EditBleHardware open={isEditModalComplete} onClose={() => setEditModalComplete(false)} />
            </>
        </PageLayout>
    );
};
export default BLEHardware;
