/* eslint-disable @typescript-eslint/ban-types */
/* eslint linebreak-style: ["error", "windows"] */
import React, { useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Modal,
} from '@scuf/common';
import PageLayoutWorkflow from '../../components/PageLayoutWorkflow';
import { dataTotalGDAWorkflow } from '../../constanFile.js';
import AddDeviceModal from '../../components/Modals/AddModalDevice';

function totalTask() {
    const [isAddDevice, setAddDevice] = useState(false);
    return (
        <div>
            <PageLayoutWorkflow data-testid="Total Task" title="Total Task">
                <div>
                    <DataTable data={dataTotalGDAWorkflow} search searchPlaceholder="Search Records">
                        <DataTable.Column field="UHID" header="UHID" sortable />
                        <DataTable.Column field="patientName" header="Patient Name" sortable />
                        <DataTable.Column field="pickupTime" header="Pickup Time" sortable />
                        <DataTable.Column field="duration" header="Duration" sortable />
                        <DataTable.Column field="dropTime" header="Drop Time" sortable />
                        <DataTable.Column field="status" header="Status" sortable />
                    </DataTable>
                </div>
                <Modal className="modal40" size="mini" closeIcon onClose={() => setAddDevice(false)} open={isAddDevice}>
                    <AddDeviceModal />
                </Modal>
            </PageLayoutWorkflow>

        </div>
    );
}
export default totalTask;
