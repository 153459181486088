/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    Icon, Button,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import AddTestType from '../../components/Modals/AddTestType';
import EditTestType from '../../components/Modals/EditTestType';
import { testTypeDetails } from '../../constanFile.js';

const TestType = (): ReactElement => {
    const [isAddModalOpenGda, setAddModalGda] = useState(false);
    const [isEditModalComplete, setEditModalComplete] = useState(false);

    const headerRightButton = () => (
        <Button
            type="primary"
            content="Add Lab Test"
            onClick={() => setAddModalGda(true)}
            icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
            iconPosition="left"
        />
    );
    const actionButtons = () => (
        <Button
            onClick={() => setEditModalComplete(true)}
            type="link"
            icon={<Icon name="edit" size="small" root="common" color="blue" />}
            content=""
        />
    );

    return (
        <PageLayout title="Lab Test Type" headerRight={headerRightButton()}>
            <>
                <DataTable
                    data={testTypeDetails}
                    search
                    searchPlaceholder="Search Records"
                    rows={10}
                >
                    <DataTable.Column field="name" header="Test Name" sortable />
                    <DataTable.Column field="code" header="Test Code" sortable />
                    <DataTable.Column field="decription" header="Test Description" sortable />
                    <DataTable.Column field="dept" header="Test Department" sortable />
                    <DataTable.Column field="conductBy" header="Test Conducted by" sortable />
                    <DataTable.Column field="conductOn" header="Test Conducted on" sortable />
                    <DataTable.Column field="suggestBy" header="Test Suggested by" sortable />
                    <DataTable.Column field="status" header="Test Status" sortable />
                    <DataTable.Column
                        field="plane"
                        header="ACTIONS"
                        renderer={actionButtons}
                    />
                </DataTable>
                <AddTestType open={isAddModalOpenGda} onClose={() => setAddModalGda(false)} />
                <EditTestType open={isEditModalComplete} onClose={() => setEditModalComplete(false)} />
            </>
        </PageLayout>
    );
};
export default TestType;
