import { IUser } from './IAuthModel';
import { IGDA } from './IGDA';
import { IIPDPaitent } from './IPatientModel';

export enum TransferMode {
    WHEELCHAIR = 'Wheelchair',
    STRETCHER = 'Stretcher'
}

export enum TaskType {
    PATIENT_TRANSFER = 'Patient Transfer',
    MEDICINE_RETURN = 'Medicine Return'
}

export enum Priority {
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low'
}

export enum CallStatus {
    UNASSIGNED= 'Unassigned',
    ASSIGNED = 'Assigned',
    COMPLETED = 'Completed'
}

export interface ICall {
    uuid?: string;
    taskType: TaskType;
    description: string;
    source: string;
    destination: string;
    patient: IIPDPaitent;
    priority: Priority;
    transferMode: TransferMode;
    assignedGDAs: IGDA[]
    callBy: IUser | null;
}

export interface ICallDetails extends ICall {
    status: CallStatus;
    gdaRequired: number;
    createdOn: string;
}
