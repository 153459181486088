/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    Icon, Button,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import AddDept from '../../components/Modals/AddDepartment';
import EditDept from '../../components/Modals/EditDepartment';
import { departmentList } from '../../constanFile.js';

const Department = (): ReactElement => {
    const [isAddModalOpenDept, setAddModalDept] = useState(false);
    const [isEditModalDept, setEditModalDept] = useState(false);

    const headerRightButton = () => (
        <Button
            type="primary"
            content="Add Deparment"
            onClick={() => setAddModalDept(true)}
            icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
            iconPosition="left"
        />
    );
    const actionButtons = () => (
        <Button
            onClick={() => setEditModalDept(true)}
            type="link"
            icon={<Icon name="edit" size="small" root="common" color="blue" />}
            content=""
        />
    );

    return (
        <PageLayout title="Department" headerRight={headerRightButton()}>
            <>
                <DataTable
                    data={departmentList}
                    search
                    searchPlaceholder="Search Records"
                    rows={10}
                >
                    <DataTable.Column field="name" header="Name" sortable />
                    <DataTable.Column field="code" header="Code" sortable />
                    <DataTable.Column field="description" header="Description" sortable />
                    <DataTable.Column field="zone" header="Zone" sortable />
                    <DataTable.Column
                        field="plane"
                        header="ACTIONS"
                        renderer={actionButtons}
                    />
                </DataTable>
                <AddDept open={isAddModalOpenDept} onClose={() => setAddModalDept(false)} />
                <EditDept open={isEditModalDept} onClose={() => setEditModalDept(false)} />
            </>
        </PageLayout>
    );
};
export default Department;
