/* eslint linebreak-style: ["error", "windows"] */
// eslint-disable-next-line
import React, { ReactElement, useState } from 'react';
import {
    Tab, Button, Divider, Card, Icon,
} from '@scuf/common';

const PatientWorkflowView = (): ReactElement => (
    <div style={{ marginLeft: 10 }}>
        <Tab defaultActiveIndex={0}>
            <Tab.Pane title="Status">
                <div>
                    <Card className="cardFlow">
                        <Card.Content>
                            <span>
                                <p className="headerCardWhite">
                                    <span className="bgCircle"> 1 </span>
                                    IPD Ward
                                </p>
                                <Divider fitted />
                                <p className="center">
                                    <div> In Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Divider />
                                <p className="center">
                                    <div> Out Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Button
                                    type="primary"
                                    icon={(
                                        <Icon
                                            name="clockwise"
                                            size="small"
                                            root="common"
                                        />
                                    )}
                                    content="Duration:0.81 Min"
                                    className="flowBtn"
                                />
                            </span>
                        </Card.Content>
                    </Card>

                    <div className="flow1">&nbsp;</div>
                    <Card className="cardFlow ml230">
                        <Card.Content>
                            <span>
                                <p className="headerCardWhite">
                                    <span className="bgCircle"> 2 </span>
                                    Patient Transfer
                                </p>
                                <Divider fitted />
                                <p className="center">
                                    <div> In Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Divider />
                                <p className="center">
                                    <div> Out Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Button
                                    type="primary"
                                    icon={(
                                        <Icon
                                            name="clockwise"
                                            size="small"
                                            root="common"
                                        />
                                    )}
                                    content="Duration:0.81 Min"
                                    className="flowBtn"
                                />
                            </span>
                        </Card.Content>
                    </Card>
                    <div className="flow2"></div>
                    <Card className="cardFlow ml460">
                        <Card.Content>
                            <span>
                                <p className="headerCardWhite">
                                    <span className="bgCircle"> 3 </span>
                                    Lab Waiting
                                </p>
                                <Divider fitted />
                                <p className="center">
                                    <div> In Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Divider />
                                <p className="center">
                                    <div> Out Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Button
                                    type="primary"
                                    icon={(
                                        <Icon
                                            name="clockwise"
                                            size="small"
                                            root="common"
                                        />
                                    )}
                                    content="Duration:0.81 Min"
                                    className="flowBtn"
                                />
                            </span>
                        </Card.Content>
                    </Card>
                    <div className="flow3"></div>
                    <Card className="cardFlow ml690">
                        <Card.Content>
                            <span>
                                <p className="headerCardWhite">
                                    <span className="bgCircle"> 4 </span>
                                    Lab Diagnosis
                                </p>
                                <Divider fitted />
                                <p className="center">
                                    <div> In Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Divider />
                                <p className="center">
                                    <div> Out Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Button
                                    type="primary"
                                    icon={(
                                        <Icon
                                            name="clockwise"
                                            size="small"
                                            root="common"
                                        />
                                    )}
                                    content="Duration:0.81 Min"
                                    className="flowBtn"
                                />
                            </span>
                        </Card.Content>
                    </Card>
                    <div className="flow4"></div>
                    <Card className="cardFlow ml920">
                        <Card.Content>
                            <span>
                                <p className="headerCardWhite">
                                    <span className="bgCircle"> 5 </span>
                                    Return Waiting
                                </p>
                                <Divider fitted />
                                <p className="center">
                                    <div> In Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Divider />
                                <p className="center">
                                    <div> Out Time</div>
                                    <div> 27-07-2021 13:02:19</div>
                                </p>
                                <Button
                                    type="primary"
                                    icon={(
                                        <Icon
                                            name="clockwise"
                                            size="small"
                                            root="common"
                                        />
                                    )}
                                    content="Duration:0.81 Min"
                                    className="flowBtn"
                                />
                            </span>
                        </Card.Content>
                    </Card>
                </div>
            </Tab.Pane>
            <Tab.Pane title="Location">
                <div>

                </div>
            </Tab.Pane>
        </Tab>
    </div>
);
export default PatientWorkflowView;
