import React from 'react';

function TaskData() {
    return (
        <div className="row-container">
            <div className="full-width m20right">
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Task ID:</p>
                    <p className="full-width">3343</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Transfer Mode:</p>
                    <p className="full-width">Strecher</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">GDA Required:</p>
                    <p className="full-width">2</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Call By:</p>
                    <p className="full-width">Leo Di Carpio</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Task Type:</p>
                    <p className="full-width">Patient Tranfer</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Source:</p>
                    <p className="full-width">C2DC</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Destination:</p>
                    <p className="full-width">XRAY</p>
                </div>
            </div>
            <div className="full-width">
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Patient Name:</p>
                    <p className="full-width">Ajinkya (1234)</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Priority:</p>
                    <p className="full-width">Low</p>
                </div>
                <div className="space-between-text-row">
                    <p className="full-width semi-bold">Time Elapsed:</p>
                    <p className="full-width">5 min</p>
                </div>
                <div>
                    <p className="full-width semi-bold">Description:</p>
                    <p>Lorem ipsum dolor sit amet , consectetur adipiscing elit ,sed do eiusmod</p>
                </div>
            </div>
        </div>
    );
}
export default TaskData;
