/* eslint-disable @typescript-eslint/no-explicit-any */
import { SidebarLayout, BadgedIcon } from '@scuf/common';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IS_SIDEBAR_COLLAPSED_BY_DEFAULT } from '../../configuration';
import Header from './Header';
import '../style.css';

type Props = {
  children: React.ReactNode
};

type State = {
  collapsed: boolean,
  activeName: string,
  expand: string[]
};

class HeaderLayout extends React.Component <Props & RouteComponentProps, State> {
    constructor(props: Props & RouteComponentProps) {
        super(props);
        this.state = {
            collapsed: IS_SIDEBAR_COLLAPSED_BY_DEFAULT,
            activeName: '',
            expand: [],
        };
        this.handleCollapse = this.handleCollapse.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
        this.handleOnHeaderTransition = this.handleOnHeaderTransition.bind(this);
    }

    handleCollapse() {
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    }

  handleItemClick:(event: any, itemProps: any) => void = (event, itemProps) => {
      const { name } = itemProps;
      this.setState({ activeName: name });
  };

  handleSubmenuClick:(event: any, itemProps: any) => void = (event, itemProps) => {
      const { expand } = this.state;
      const { name } = itemProps;
      const expanderIndex = expand.findIndex((element) => element === name);
      if (expanderIndex >= 0) {
          expand.splice(expanderIndex, 1);
      } else {
          expand.push(name);
      }
      this.setState({ expand });
  };

  handleOnHeaderTransition(data: {
    compressed: boolean;
    collapsed: boolean;
  }) {
      if (data.collapsed || data.compressed) {
          this.setState({ collapsed: true });
      }
  }

  render() {
      const { collapsed, activeName, expand } = this.state;
      const { children, history } = this.props;
      let feature;

      if (window.localStorage.getItem('userRole') === 'admin') {
          feature = (
              <div>
                  <SidebarLayout.Sidebar.Item
                      content="IPD Dashboard"
                      activeName={activeName}
                      name="IPD Dashboard"
                      icon={<BadgedIcon root="common" name="dashboard" />}
                      onClick={() => history.push('/ipd-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Doctor"
                      activeName={activeName}
                      name="Doctor"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/doctor-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Assign Test"
                      activeName={activeName}
                      name="Assign Test"
                      icon={<BadgedIcon root="common" name="clipboard" />}
                      onClick={() => history.push('/assign-test')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="GDA Dashboard"
                      activeName={activeName}
                      name="GDA Dashboard"
                      icon={<BadgedIcon root="common" name="dashboard" />}
                      onClick={() => history.push('/gda-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Lab Dashboard"
                      activeName={activeName}
                      name="Lab Dashboard"
                      icon={<BadgedIcon root="common" name="dashboard" />}
                      onClick={() => history.push('/lab-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="BLE Registration"
                      activeName={activeName}
                      name="BLE Registration"
                      icon={<BadgedIcon root="common" name="clipboard" />}
                      onClick={() => history.push('/ble-regitration')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="System Preference"
                      activeName={activeName}
                      name="System Preference"
                      icon={<BadgedIcon root="common" name="wifi" />}
                      onClick={() => history.push('/ble-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Tracking"
                      activeName={activeName}
                      name="Tracking"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/tracking')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Lab Test Type"
                      activeName={activeName}
                      name="Lab Test Type"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/test-type')}
                  />
                  <SidebarLayout.Sidebar.Submenu
                      content="Workflow"
                      activeName={activeName}
                      name="WorkflowExpander"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={this.handleSubmenuClick}
                      open={expand.includes('WorkflowExpander')}
                  >
                      <SidebarLayout.Sidebar.Item
                          content="Patient Workflow"
                          activeName={activeName}
                          name="PatientWorkflow"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/patient-workflow')}
                      />
                      <SidebarLayout.Sidebar.Item
                          content="GDA Workflow"
                          activeName={activeName}
                          name="GDAWorkflow"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/gda-workflow')}
                      />
                      <SidebarLayout.Sidebar.Item
                          content="Nurse Workflow"
                          activeName={activeName}
                          name="NurseWorkflow"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/nurse-workflow')}
                      />
                  </SidebarLayout.Sidebar.Submenu>
                  <SidebarLayout.Sidebar.Submenu
                      content="Report"
                      activeName={activeName}
                      name="reportExpander"
                      icon={<BadgedIcon root="common" name="report" />}
                      onClick={this.handleSubmenuClick}
                      open={expand.includes('reportExpander')}
                  >
                      <SidebarLayout.Sidebar.Item
                          content="Doctor"
                          activeName={activeName}
                          name="DoctorReport"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/doctor-report')}
                      />
                      <SidebarLayout.Sidebar.Item
                          content="Nurse"
                          activeName={activeName}
                          name="NurseReport"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/nurse-report')}
                      />
                      <SidebarLayout.Sidebar.Item
                          content="Patient"
                          activeName={activeName}
                          name="PatientReport"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/patient-report')}
                      />
                  </SidebarLayout.Sidebar.Submenu>
                  <SidebarLayout.Sidebar.Item
                      content="Department"
                      activeName={activeName}
                      name="Department"
                      icon={<BadgedIcon root="common" name="edit" />}
                      onClick={() => history.push('/department')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Task"
                      activeName={activeName}
                      name="Task"
                      icon={<BadgedIcon root="common" name="clipboard" />}
                      onClick={() => history.push('/task-master')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Notification"
                      activeName={activeName}
                      name="Notification"
                      icon={<BadgedIcon root="common" name="clipboard" />}
                      onClick={() => history.push('/notification')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="BLE Hardware"
                      activeName={activeName}
                      name="BLE Hardware"
                      icon={<BadgedIcon root="common" name="clipboard" />}
                      onClick={() => history.push('/ble-hardware')}
                  />

              </div>
          );
      } else if (window.localStorage.getItem('userRole') === 'doctor') {
          feature = (
              <SidebarLayout.Sidebar.Item
                  content="Doctor"
                  activeName={activeName}
                  name="Doctor"
                  icon={<BadgedIcon root="common" name="user" />}
                  onClick={() => history.push('/doctor-dashboard')}
              />
          );
      } else if (window.localStorage.getItem('userRole') === 'gda') {
          feature = (
              <div>
                  <SidebarLayout.Sidebar.Item
                      content="GDA Dashboard"
                      activeName={activeName}
                      name="GDA Dashboard"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/gda-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Tracking"
                      activeName={activeName}
                      name="Tracking"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/tracking')}
                  />
                  <SidebarLayout.Sidebar.Submenu
                      content="Workflow"
                      activeName={activeName}
                      name="WorkflowExpander"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={this.handleSubmenuClick}
                      open={expand.includes('WorkflowExpander')}
                  >
                      <SidebarLayout.Sidebar.Item
                          content="Patient Workflow"
                          activeName={activeName}
                          name="PatientWorkflow"
                          icon={<BadgedIcon root="common" name="menu" />}
                          onClick={() => history.push('/patient-workflow')}
                      />
                  </SidebarLayout.Sidebar.Submenu>
              </div>
          );
      } else if (window.localStorage.getItem('userRole') === 'nurse') {
          feature = (
              <div>
                  <SidebarLayout.Sidebar.Item
                      content="Nurse"
                      activeName={activeName}
                      name="Nurse"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/nurse-dashboard')}
                  />
                  <SidebarLayout.Sidebar.Item
                      content="Head Nurse"
                      activeName={activeName}
                      name="Head Nurse"
                      icon={<BadgedIcon root="common" name="user" />}
                      onClick={() => history.push('/headnurse-dashboard')}
                  />
              </div>
          );
      }

      return (
          <div data-testid={collapsed ? 'collapsed' : 'notcollapsed'} style={{ height: '100%', overflowY: 'hidden' }}>
              <Header
                  onMenuToggle={this.handleCollapse}
                  onHeaderTransition={this.handleOnHeaderTransition}
              />
              <SidebarLayout className="sidebar-layout-height" collapsed={collapsed}>

                  <SidebarLayout.Sidebar>
                      {feature}

                  </SidebarLayout.Sidebar>
                  <SidebarLayout.Content>

                      {children}

                  </SidebarLayout.Content>
              </SidebarLayout>
          </div>
      );
  }
}

export default withRouter(HeaderLayout);
