import React, { useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Button, Modal,
} from '@scuf/common';
import PageLayoutBle from '../../components/PageLayoutBle';
import { dataSystemLab } from '../../constanFile.js';
import AddDeviceModal from '../../components/Modals/AddModalDevice';

function Lab() {
    const [isAddDevice, setAddDevice] = useState(false);
    return (
        <div>
            <PageLayoutBle data-testid="labPage" title="Laboratory Tests" headerRight={<Button type="primary" content="Add Device" onClick={() => setAddDevice(true)} />}>
                <DataTable data={dataSystemLab} search searchPlaceholder="Search Records">
                    <DataTable.Column field="bleId" header="ID" sortable />
                    <DataTable.Column field="bleName" header="BLE Name" sortable />
                    <DataTable.Column field="macAddress" header="MAC Address" sortable />
                    <DataTable.Column field="password" header="Password" sortable />
                    <DataTable.Column field="ipAddress" header="IP Address" sortable />
                    <DataTable.Column field="zone" header="Zone Group" sortable />
                </DataTable>
                <Modal className="modal40" size="mini" closeIcon onClose={() => setAddDevice(false)} open={isAddDevice}>
                    <AddDeviceModal />
                </Modal>

            </PageLayoutBle>
        </div>
    );
}
export default Lab;
