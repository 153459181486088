/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { DataTable } from '@scuf/datatable';
import { Icon, Button } from '@scuf/common';
import { dataGda } from '../../../constanFile.js';
const ManageGDA = () => {
    const actionButtons = () => (
        <Button
            type="link"
            icon={<Icon name="badge-important" size="small" root="common" color="blue" />}
            content=""
        />
    );
    return (
        <>
            <DataTable data={dataGda} search searchPlaceholder="Search Records">
                <DataTable.Column field="gda_id" header="ID" sortable />
                <DataTable.Column field="GDAName" header="GDA NAME" sortable />
                <DataTable.Column field="department" header="DEPARTMENT" sortable />
                <DataTable.Column field="phone_number" header="PHONE NUMBER" sortable />
                <DataTable.Column field="status" header="Status" sortable />
                <DataTable.Column field="tag_no" header="TAG NO." sortable />
                <DataTable.Column field="live_location" header="LIVE LOCATION" sortable />
                <DataTable.Column
                    field="plane"
                    header="ACTIONS"
                    renderer={actionButtons}
                />
            </DataTable>
        </>
    );
};

export default ManageGDA;
