export default {
    Three_xs: 0.125,
    Two_xs: 0.25,
    xs: 0.5,
    sm: 0.75,
    md: 1,
    lg: 1.5,
    xl: 2,
    Two_xl: 2.5,
    Three_xl: 3,
    Four_xl: 4,
};
