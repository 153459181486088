/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint linebreak-style: ["error", "windows"] */
import React, { useState } from 'react';
import {
    Button, Select, Modal, Input, TextArea, DatePicker,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';

import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';
import {
    departmentList,
} from '../../constanFile.js';

interface Props extends IModalProps, IAuthContextProps {}
const AddTestType = ({
    open, onClose, ...others
}: Props) => {
    const [isTestName, setTestName] = useState('');
    const [isTestCode, setTestCode] = useState('');
    const [isDepartment, setDepartment] = useState('');
    const [isDescription, setDescription] = useState('');
    const [isConductBy, setConductBy] = useState('');
    const [isSuggestBy, setSuggestBy] = useState('');

    const isSubmitEnabled = () => isTestName?.length && isTestCode?.length
    && isDepartment?.length && isDescription?.length && isConductBy?.length && isSuggestBy?.length;
    return (
        <Modal
            data-testid="addTestTypeModal"
            className="modal500"
            size="large"
            closeIcon
            onClose={onClose}
            open={open}
            {...others}
        >
            <div>
                <h2>Add Lab Test Type</h2>
                <form>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Test Name"
                                label="Test Name"
                                className="w15"
                                onChange={setTestName}
                                value={isTestName}
                            />
                        </span>
                        <span className="m10">
                            <Input
                                placeholder="Test Code"
                                className="w15"
                                label="Test Code"
                                onChange={setTestCode}
                                value={isTestCode}
                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <Select
                                search
                                label="Department"
                                className="wselect15"
                                indicator="required"
                                placeholder="Select"
                                options={departmentList}
                                onChange={setDepartment}
                                value={isDepartment}
                            />
                        </span>
                        <span className="m10">
                            <TextArea
                                label="Test Description"
                                rows={1}
                                placeholder="Description..."
                                onChange={setDescription}
                                value={isDescription}
                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Conducted By"
                                label="Conducted By"
                                onChange={setConductBy}
                                value={isConductBy}
                            />
                        </span>
                        <span className="m10">
                            <DatePicker
                                label="Conduct On"
                                placeholder="Select Date"

                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Suggested By"
                                label="Conduct By"
                                onChange={setSuggestBy}
                                value={isSuggestBy}
                            />
                        </span>
                        <span className="m10">
                            <Select
                                search
                                label="Department"
                                indicator="required"
                                placeholder="Select"
                                options={departmentList}
                                onChange={setDepartment}
                                value={isDepartment}
                            />
                        </span>
                    </div>

                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary" actionType="submit">Cancel</Button>
                        </span>
                        <span>
                            <Button type="primary" disabled={!isSubmitEnabled()} actionType="submit">Confirm</Button>
                        </span>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default withAuthContext(AddTestType);
