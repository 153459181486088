/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement } from 'react';
import {
    Button, Input, Grid, Select, Tab, Card, Icon, Divider,
} from '@scuf/common';
import { departmentListSelect, questiontListSelect } from '../constanFile.js';

const profile = (): ReactElement => (

    <div className="mt100">
        <Grid>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Card>
                        <Card.Content>
                            <p>
                                <span>
                                    <Icon root="common" name="user" />
                                </span>
                                <span className="ml10">
                                    Name : Admin  Admin
                                </span>
                            </p>
                            <Divider fitted />
                            <p>
                                <span>
                                    <Icon root="common" name="user-operations" />
                                </span>
                                <span className="ml10">
                                    Role : Admin
                                </span>
                            </p>

                            <Divider fitted />
                            <p>
                                <span>
                                    <Icon root="common" name="skyscrapers" />
                                </span>
                                <span className="ml10">
                                    Department : Neurology
                                </span>
                            </p>
                            <Divider fitted />

                        </Card.Content>
                    </Card>
                </Grid.Column>

                <Grid.Column width={8} className="backWhite">

                    <Tab defaultActiveIndex={0}>
                        <Tab.Pane title="Profile">
                            <form className="profileForm">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={6} className="ml250">
                                            <div>
                                                <label htmlFor="photo-upload" className="custom-file-upload fas">
                                                    <div className="img-wrap img-upload">
                                                        <img
                                                            src="https://e7.pngegg.com/pngimages/241/832/png-clipart-business-management-computer-software-apartment-organization-user-avatar-blue-angle-thumbnail.png"
                                                            alt="logo"
                                                        />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        className="imgInput"
                                                        accept="image/*"
                                                        onChange={() => alert('hiii')}
                                                        id="photo-upload"
                                                    />
                                                </label>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Input
                                                    placeholder="Name"
                                                    value="Admin"
                                                    label="Name"
                                                    className="profileInput"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Input
                                                    placeholder="Email"
                                                    value="admin@honeywell.com"
                                                    className="profileInput"
                                                    label="Email"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Input
                                                    placeholder="Contact No."
                                                    value="1234567890"
                                                    className="profileInput"
                                                    label="Contact No."
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <span>
                                                <Select
                                                    search
                                                    placeholder="Select Department"
                                                    options={departmentListSelect}
                                                    name="department"
                                                    defaultValue="Neurology"
                                                    className="profileSelect"
                                                    label="Department"
                                                />
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Input
                                                    placeholder="User Name"
                                                    value="Admin"
                                                    className="profileInput"
                                                    label="User Name"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Input
                                                    placeholder="Role"
                                                    value="Admin"
                                                    className="profileInput"
                                                    label="Role"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <div className="modal-bottom-button-container">
                                    <div className="ml250 mt100">
                                        <Button type="primary" actionType="submit">Update Profile</Button>
                                    </div>
                                </div>
                            </form>
                        </Tab.Pane>
                        <Tab.Pane title="Change Password">
                            <form className="profileForm">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <div>
                                                <Input
                                                    placeholder="Old Password"
                                                    type="password"
                                                    name="oldPassword"
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <div>
                                                <Input
                                                    placeholder="New Password"
                                                    type="password"
                                                    name="newPassword"
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Input
                                                    placeholder="Confirm Password"
                                                    type="password"
                                                    name="confirmPassword"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <div className="modal-bottom-button-container">
                                    <div className="ml120 ">
                                        <Button type="primary" actionType="submit">Save Password</Button>
                                    </div>
                                </div>
                            </form>
                        </Tab.Pane>
                        <Tab.Pane title="Security Question & Answer">
                            <form className="profileForm">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <div>
                                                <Select
                                                    search
                                                    className="profileSelect"
                                                    placeholder="Select Question"
                                                    options={questiontListSelect}
                                                    name="question"
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <div>
                                                <Input
                                                    placeholder="Enter Answer"
                                                    name="answer"
                                                    className="profileInput"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <div className="modal-bottom-button-container">
                                    <div className="ml120">
                                        <Button type="primary" actionType="submit">Save </Button>
                                    </div>
                                </div>
                            </form>
                        </Tab.Pane>
                    </Tab>

                </Grid.Column>
            </Grid.Row>
        </Grid>

    </div>
);
export default profile;
