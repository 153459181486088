/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint linebreak-style: ["error", "windows"] */
import React, { useState } from 'react';
import {
    Button, Modal, Input, TextArea,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';

import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';

interface Props extends IModalProps, IAuthContextProps {}
const AddTask = ({
    open, onClose, ...others
}: Props) => {
    const [isTaskName, setTaskName] = useState('');
    const [isDescription, setDescription] = useState('');
    const [isTargetEntity, setTargetEntity] = useState('');
    const [isEstimatedTime, setEstimatedTime] = useState('');

    const isSubmitEnabled = () => isTaskName?.length && isDescription?.length
    && isTargetEntity?.length && isEstimatedTime?.length;
    return (
        <Modal
            data-testid="addTaskModal"
            className="modal500"
            size="large"
            closeIcon
            onClose={onClose}
            open={open}
            {...others}
        >
            <div>
                <h2>Add Task</h2>
                <form>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Task Name"
                                label="Task Name"
                                className="w15"
                                onChange={setTaskName}
                                value={isTaskName}
                            />
                        </span>
                        <span className="m10">
                            <TextArea
                                label="Task Description"
                                rows={1}
                                placeholder="Description..."
                                onChange={setDescription}
                                value={isDescription}
                            />
                        </span>
                    </div>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Target Entity"
                                label="Target Entity"
                                className="w15"
                                onChange={setTargetEntity}
                                value={isTargetEntity}
                            />
                        </span>
                        <span className="m10">
                            <Input
                                placeholder="Task Estimated Time"
                                label="Task Estimated Time"
                                className="w15"
                                onChange={setEstimatedTime}
                                value={isEstimatedTime}
                            />
                        </span>
                    </div>

                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary" actionType="submit">Cancel</Button>
                        </span>
                        <span>
                            <Button type="primary" disabled={!isSubmitEnabled()} actionType="submit">Confirm</Button>
                        </span>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default withAuthContext(AddTask);
