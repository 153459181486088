/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement } from 'react';
import {
    Notification, Card,
} from '@scuf/common';
import { LocalDate } from '@scuf/localization';
import PageLayout from '../components/PageLayout';
const notification = (): ReactElement => (
    <PageLayout title="Notification">
        <Card>
            <Card.Header title="Patient Test" />
            <Card.Content>
                <Notification
                    hasIcon
                    title="Patient Test Complete"
                    severity="success"
                    datetime={new Date().toLocaleString()}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Nisha Mishra </p>
                    <p> Location :  </p>
                </Notification>
                <Notification
                    hasIcon
                    title="Patient Test Complete"
                    severity="success"
                    datetime={new Date().toLocaleString()}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Sandip Adling </p>
                    <p> Location :  </p>
                </Notification>
            </Card.Content>
        </Card>
        <Card>
            <Card.Header title="Test Assign To Patient" />
            <Card.Content>
                <Notification
                    title="Patient Test Assign"
                    severity="important"
                    datetime={new Date().toLocaleString()}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    Patient Name : Poonam Shinde
                </Notification>
                <Notification
                    title="Patient Test Assign"
                    severity="important"
                    datetime={new Date().toLocaleString()}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    Patient Name : Poonam Shinde
                </Notification>
            </Card.Content>
        </Card>
        <Card>
            <Card.Header title="Patient In" />
            <Card.Content>
                <Notification
                    title="IN"
                    severity="information"
                    datetime={<LocalDate value={new Date()} />}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Sandip Adling </p>
                    <p> Location :  </p>
                </Notification>
                <Notification
                    title="IN"
                    severity="information"
                    datetime={<LocalDate value={new Date()} fromNow />}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Sandip Adling </p>
                    <p> Location :  </p>
                </Notification>
            </Card.Content>
        </Card>
        <Card>
            <Card.Header title="Exceeded Waiting Time" />
            <Card.Content>
                <Notification
                    title="Waiting Time"
                    severity="important"
                    datetime={<LocalDate value={new Date()} />}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Nisha</p>
                    <p> Patient Location : Lab</p>
                    <p> Waiting Time : 0.60 Hrs</p>
                </Notification>
                <Notification
                    title="Waiting Time"
                    severity="important"
                    datetime={<LocalDate value={new Date()} />}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Nisha</p>
                    <p> Patient Location : Lab</p>
                    <p> Waiting Time : 0.60 Hrs</p>
                </Notification>
            </Card.Content>
        </Card>
        <Card>
            <Card.Header title="GDA Free" />
            <Card.Content>
                <Notification
                    title="Waiting Time"
                    severity="information"
                    datetime={<LocalDate value={new Date()} />}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Nisha</p>
                    <p> Patient Location : Lab</p>
                    <p> Waiting Time : 0.60 Hrs</p>
                </Notification>
                <Notification
                    title="Waiting Time"
                    severity="information"
                    datetime={<LocalDate value={new Date()} />}
                    onCloseClick={() => alert('Close Clicked')}
                >
                    <p> Patient Name : Nisha</p>
                    <p> Patient Location : Lab</p>
                    <p> Waiting Time : 0.60 Hrs</p>
                </Notification>

            </Card.Content>
        </Card>
    </PageLayout>

);
export default notification;
