/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint linebreak-style: ["error", "windows"] */
import React, { useState } from 'react';
import {
    Button, Modal, Input, TextArea,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';

import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';

interface Props extends IModalProps, IAuthContextProps {}
const EditDepartment = ({
    open, onClose, ...others
}: Props) => {
    const [isName, setName] = useState('');
    const [isCode, setCode] = useState('');
    const [isDescription, setDescription] = useState('');
    const [isZone, setZone] = useState('');

    const isSubmitEnabled = () => isName?.length && isCode?.length && isDescription?.length && isZone?.length;
    return (
        <Modal
            data-testid="editDeptModal"
            className="modal500"
            size="large"
            closeIcon
            onClose={onClose}
            open={open}
            {...others}
        >
            <div>
                <h2>Edit Department</h2>
                <form>
                    <div className="m10">
                        <span className="m10">
                            <Input
                                placeholder="Name"
                                label="Name"
                                className="w15"
                                onChange={setName}
                                value={isName}
                            />
                        </span>
                        <span className="m10">
                            <Input
                                placeholder="Code"
                                className="w15"
                                label="Code"
                                onChange={setCode}
                                value={isCode}
                            />
                        </span>
                    </div>
                    <div className="m10">

                        <span className="m10">
                            <TextArea
                                label="Description"
                                rows={1}
                                placeholder="Description..."
                                onChange={setDescription}
                                value={isDescription}
                            />
                        </span>
                        <span className="m10">
                            <Input
                                placeholder="Zone"
                                className="w15"
                                label="Zone"
                                onChange={setZone}
                                value={isZone}
                            />
                        </span>
                    </div>

                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary" actionType="submit">Cancel</Button>
                        </span>
                        <span>
                            <Button type="primary" disabled={!isSubmitEnabled()} actionType="submit">Confirm</Button>
                        </span>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default withAuthContext(EditDepartment);
