/* eslint-disable react/jsx-props-no-spreading */
/* eslint linebreak-style: ["error", "windows"] */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
    Button, Select, TextArea, Search, InputLabel, Icon, Modal,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';

import { createCallApi } from '../../__mocks__/API/taskAPI';
import { Priority, TaskType, TransferMode } from '../../models/ICall';
import { IIPDPaitent } from '../../models/IPatientModel';
import { getPatientBySearchTerm } from '../../__mocks__/API/patientsAPI';
import { getGDABySearchTerm } from '../../__mocks__/API/gdaAPI';
import { IGDA } from '../../models/IGDA';
import withAuthContext from '../../helpers/Auth/withAuth';
import { IAuthContextProps } from '../../models/IAuthModel';

const prioritySelect = [{ value: 'High', text: 'High' }, { value: 'Low', text: 'Low' }];
const allTaskTypess = [{ value: TaskType.PATIENT_TRANSFER, text: 'Patient Transfer' }, { value: TaskType.MEDICINE_RETURN, text: 'Medicine Return' }];
const sources = [{ value: 'C2DC', text: 'C2DC' }, { value: 'XRAY', text: 'XRAY' }];
const allTransferModes = [{ value: TransferMode.STRETCHER, text: 'Stretcher' }, { value: TransferMode.WHEELCHAIR, text: 'Wheelchair' }];

interface Props extends IModalProps, IAuthContextProps {}

const CreateCall = ({
    open, onClose, auth, ...others
}: Props) => {
    const [taskType, setTaskType] = useState<TaskType>(TaskType.PATIENT_TRANSFER);
    const [description, setDescription] = useState('');
    const [source, setSource] = useState('');
    const [destination, setDestination] = useState('');
    const [patientList, setPatientList] = useState<IIPDPaitent[]>([]);
    const [gdaList, setGdaList] = useState<IGDA[]>([]);
    const [selectedPatient, setSelectedPatient] = useState<any>({});
    const [priority, setPriority] = useState<Priority>(Priority.LOW);
    const [transferMode, setTransferMode] = useState<TransferMode>(TransferMode.WHEELCHAIR);
    const [selectedGDAs, setSelectedGDAs] = useState<IGDA[]>([]);
    const [selectGdaFieldValue, setSelectGdaFieldValue] = useState('');
    const getPatients = (searchTerm: string | undefined) => {
        if (searchTerm) {
            getPatientBySearchTerm(searchTerm).then((data) => {
                setPatientList(data);
            });
        }
        setPatientList([]);
    };

    const isSubmitEnabled = () => description?.length && source?.length && destination.length;

    const getGDAs = (searchTerm: string | undefined) => {
        if (searchTerm) {
            getGDABySearchTerm(searchTerm).then((data) => {
                if (!data.some((gda) => selectedGDAs.some((d) => d.uuid === gda.uuid))) { setGdaList(data); }
            });
        }
        setGdaList([]);
    };

    const onSelectGda = (gda: any) => {
        setSelectedGDAs((prevGDAs) => [...prevGDAs, gda]);
        setGdaList((prevGda) => [...prevGda.filter((g) => g.uuid !== gda.uuid)]);
        setSelectGdaFieldValue(gda.name);
    };

    const removeGDA = (gda: IGDA) => {
        setSelectedGDAs((prevGda) => [...prevGda.filter((g) => g.uuid !== gda.uuid)]);
        setSelectGdaFieldValue('');
    };

    return (
        <Modal
            data-testid="createCallModal"
            className="modal500"
            size="small"
            closeIcon
            onClose={onClose}
            open={open}
            {...others}
        >
            <div>
                <h2>Create Call</h2>
                <form>
                    <div style={{ margin: '0 10%' }}>
                        <Select
                            label="Task Type"
                            className="default-input"
                            indicator="required"
                            placeholder="Select a Task Type"
                            options={allTaskTypess}
                            onChange={setTaskType}
                            value={taskType}
                        />
                        <TextArea
                            rows={1}
                            label="Description"
                            className="default-input"
                            indicator="required"
                            placeholder="Description..."
                            onChange={setDescription}
                            value={description}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '6%', width: '100%' }}>
                                <Select
                                    search
                                    label="Source"
                                    className="default-input"
                                    indicator="required"
                                    placeholder="Select Source"
                                    options={sources}
                                    value={source}
                                    onChange={setSource}
                                />
                            </div>
                            <Select
                                label="Destination"
                                className="default-input"
                                indicator="required"
                                placeholder="Select Destination"
                                options={sources}
                                value={destination}
                                onChange={setDestination}
                            />
                        </div>
                        <div className="m10">
                            <InputLabel label="Patient" />
                            <Search
                                placeholder="Search by UHID"
                                results={patientList}
                                className="search-default-input"
                                value={selectedPatient.name}
                                onResultSelect={(result) => setSelectedPatient(result)}
                                onSearchChange={(value: string | undefined) => getPatients(value)}
                                resultRenderer={(props) => {
                                    if (props.status === 'active') {
                                        return <>{`${props.name}  (${props.uhid})`}</>;
                                    }
                                    return <></>;
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '6%', width: '60%' }}>
                                <Select
                                    label="Priority"
                                    indicator="required"
                                    className="default-input"
                                    placeholder="Select Priority"
                                    options={prioritySelect}
                                    onChange={setPriority}
                                    value={priority}
                                />
                            </div>
                            <Select
                                label="Tranfer Mode"
                                indicator="required"
                                className="default-input"
                                placeholder="Select Tranfer Mode"
                                options={allTransferModes}
                                onChange={setTransferMode}
                                value={transferMode}
                            />
                        </div>
                        <div className="m10">
                            <InputLabel label="Assign To" />
                            <Search
                                placeholder="Search GDA by ID"
                                results={gdaList}
                                indicator="required"
                                className="search-default-input"
                                onSearchChange={(e) => getGDAs(e)}
                                resultRenderer={(props) => {
                                    if (props.status === 'active') {
                                        return <>{`${props.name}  (${props.uuid})`}</>;
                                    }
                                    return <></>;
                                }}
                                onResultSelect={(result) => onSelectGda(result)}
                                value={selectGdaFieldValue}
                            />
                        </div>
                        <div>
                            <InputLabel label="Assigned To:" />
                            {selectedGDAs.map((gda) => (
                                <div style={{ marginTop: '7px' }}>
                                    <Button type="link" icon={<Button type="link" onClick={() => removeGDA(gda)}><Icon name="delete" size="small" root="common" /></Button>} content="" />
                                    <span style={{ marginLeft: '5px' }}>{gda.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary" actionType="submit">Cancel</Button>
                        </span>
                        <span>
                            <Button
                                onClick={() => createCallApi({
                                    description,
                                    destination,
                                    patient: selectedPatient,
                                    priority,
                                    transferMode,
                                    source,
                                    taskType,
                                    assignedGDAs: selectedGDAs,
                                    callBy: auth.user,
                                }).then((completed) => {
                                    if (completed && onClose) {
                                        onClose();
                                    }
                                })}
                                type="primary"
                                actionType="button"
                                disabled={!isSubmitEnabled()}
                            >
                                Confirm
                            </Button>
                        </span>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default withAuthContext(CreateCall);
