import {
    BLEBLE,
} from '../../models/BLEBLE';

const device: BLEBLE[] = [];

export const addDeviceApi = (call: BLEBLE) => new Promise<boolean>((resolve) => {
    device.push({ ...call });
    resolve(true);
});

export const dataSystemBleGatewayPush = () => new Promise<BLEBLE[]>((resolve) => {
    resolve(device);
});
