/* eslint linebreak-style: ["error", "windows"] */
import React, { ReactElement, useState } from 'react';
import {
    Icon, Button,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import PageLayout from '../../components/PageLayout';
import AddTask from '../../components/Modals/AddTask';
import EditTask from '../../components/Modals/EditTask';
import { taskDetails } from '../../constanFile.js';

const TaskMaster = (): ReactElement => {
    const [isAddModalTask, setAddModalTask] = useState(false);
    const [isEditModalTask, setEditModalTask] = useState(false);

    const headerRightButton = () => (
        <Button
            type="primary"
            content="Add Task"
            onClick={() => setAddModalTask(true)}
            icon={<Icon root="common" name="slidercontrols-plus" color="white" />}
            iconPosition="left"
        />
    );
    const actionButtons = () => (
        <Button
            onClick={() => setEditModalTask(true)}
            type="link"
            icon={<Icon name="edit" size="small" root="common" color="blue" />}
            content=""
        />
    );

    return (
        <PageLayout title="Task Master" headerRight={headerRightButton()}>
            <>
                <DataTable
                    data={taskDetails}
                    search
                    searchPlaceholder="Search Records"
                    rows={10}
                >
                    <DataTable.Column field="taskID" header="Task ID" sortable />
                    <DataTable.Column field="name" header="Task Name" sortable />
                    <DataTable.Column field="decription" header="Task Description" sortable />
                    <DataTable.Column field="targetEntity" header="Target Entity" sortable />
                    <DataTable.Column field="estimateTime" header="Task Estimated Time" sortable />
                    <DataTable.Column field="status" header="Task Status" sortable />
                    <DataTable.Column
                        field="plane"
                        header="ACTIONS"
                        renderer={actionButtons}
                    />
                </DataTable>
                <AddTask open={isAddModalTask} onClose={() => setAddModalTask(false)} />
                <EditTask open={isEditModalTask} onClose={() => setEditModalTask(false)} />
            </>
        </PageLayout>
    );
};
export default TaskMaster;
