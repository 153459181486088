import React, { ReactElement, useState } from 'react';
import {
    VerticalMenu, Breadcrumb,
} from '@scuf/common';
import '../../components/GDAStyle.css';
import Dermatology from './Dermatology';
import Cardiology from './Cardiology';
import Neurology from './Neurology';
import Ophthalmology from './Ophthalmology';
import Orthopedics from './Orthopedics';
import InpatientService from './InpatientService';

const PatientWorkflow = (): ReactElement => {
    const [selectedMenuIndex, setMenuIndex] = useState(0);
    return (
        <div style={{ marginLeft: 10 }}>
            <div className="systemPreference">

                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => alert('Home')}>
                        Workflow
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Patient Workflow
                    </Breadcrumb.Item>
                </Breadcrumb>
                <VerticalMenu>
                    <VerticalMenu.Header> All </VerticalMenu.Header>

                    {(() => {
                        if (selectedMenuIndex === 0) {
                            return (
                                <VerticalMenu.Item
                                    active
                                    onClick={() => setMenuIndex(0)}
                                >
                                    Cardiology
                                </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(0)}> Cardiology  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 1) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(1)}> Dermatology  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(1)}> Dermatology  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 2) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(2)}> Neurology  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(2)}> Neurology  </VerticalMenu.Item>
                        );
                    })()}

                    {(() => {
                        if (selectedMenuIndex === 3) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(3)}> Ophthalmology  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(3)}> Ophthalmology  </VerticalMenu.Item>
                        );
                    })()}
                    {(() => {
                        if (selectedMenuIndex === 4) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(4)}> Orthopedics  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(4)}> Orthopedics  </VerticalMenu.Item>
                        );
                    })()}
                    {(() => {
                        if (selectedMenuIndex === 5) {
                            return (
                                <VerticalMenu.Item active onClick={() => setMenuIndex(5)}> Inpatient Service (IP)  </VerticalMenu.Item>
                            );
                        }
                        return (
                            <VerticalMenu.Item onClick={() => setMenuIndex(5)}> Inpatient Service (IP)  </VerticalMenu.Item>
                        );
                    })()}

                </VerticalMenu>
            </div>
            {(() => {
                switch (selectedMenuIndex) {
                    case 1: return <Dermatology />;

                    case 2: return <Neurology />;

                    case 3: return <Ophthalmology />;
                    case 4: return <Orthopedics />;
                    case 5: return <InpatientService />;

                    default: return <Cardiology />;
                }
            })()}
        </div>
    );
};
export default PatientWorkflow;
