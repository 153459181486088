import { IIPDPaitent } from '../../models/IPatientModel';

const patients: IIPDPaitent[] = [{
    uhid: '1234',
    name: 'Luke',
    registrationDate: '02-Apr-21',
    department: 'Orthopedics',
    wardNumber: 'C2DC/22',
    phoneNumber: '956-367-8945',
    bleTagNo: 'Card 2',
    liveLocation: 'Cancer GF',
    status: 'active',
}, {
    uhid: '1237',
    name: 'Christopher',
    registrationDate: '04-May-21',
    department: 'Orthopedics',
    wardNumber: 'C3DC/11',
    phoneNumber: '936-347-8555',
    bleTagNo: 'Card 3',
    liveLocation: 'C3DC',
    status: 'active',
}, {
    uhid: '9887',
    name: 'Nolan ',
    registrationDate: '22-Jan-21',
    department: 'Cardiology',
    wardNumber: 'C2DC/21',
    phoneNumber: '956-367-8945',
    bleTagNo: null,
    liveLocation: null,
    status: 'inactive',
}, {
    uhid: '3321',
    name: 'El Romanov',
    registrationDate: '04-June-21',
    department: 'Pediatrics',
    wardNumber: 'C3DC/12',
    phoneNumber: '936-347-8555',
    bleTagNo: null,
    liveLocation: null,
    status: 'inactive',
}];

export function getAllActivePatients() {
    return new Promise<IIPDPaitent[]>((resolve) => resolve(patients.filter((patient) => patient.status === 'active')));
}

export function getAllInactivePatients() {
    return new Promise<IIPDPaitent[]>((resolve) => resolve(patients.filter((patient) => patient.status === 'inactive')));
}

export function getPatientBySearchTerm(searchTerm: string) {
    return new Promise<IIPDPaitent[]>((resolve) => resolve(patients.filter((patient) => patient.uhid.includes(searchTerm) || patient.name.toLocaleLowerCase().includes(searchTerm.toLowerCase()))));
}
