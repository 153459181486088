import React, { useState } from 'react';

import { DataTable } from '@scuf/datatable';
import {
    Icon, Button, Search, Divider, InputLabel, Modal,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';
import TaskData from '../TaskData';
import {
    AssignTo, assignedTo, dataEditAssign,
} from '../../constanFile.js';
import ConfirmModal from './ConfirmModal';

interface Props extends IModalProps {
    modalTitle: string
    reOpen: () => void
}

const CallDetailsAssignGDAModal = ({
    open, onClose, modalTitle, reOpen,
}:Props) => {
    const [selectedRecommendedGDAs, setSelectedRecommendedGDAs] = useState<{name: string, liveLocation: string, id: string}[]>([]);
    const [cancelCallModal, setCancelCallModal] = useState(false);
    const onCancelCall = () => {
        if (onClose) {
            onClose();
        }
        setCancelCallModal(true);
    };

    const onCancelConfirmModal = () => {
        reOpen();
        setCancelCallModal(false);
    };
    return (
        <>
            <Modal className="modal600" size="small" closeIcon onClose={onClose} open={open}>
                <h1 className="modal-title">{modalTitle}</h1>
                <form>
                    <div style={{ margin: '0 5%' }}>
                        <div className="m10">
                            <TaskData />
                            <Divider />
                            <div>
                                <InputLabel label="Assign To" />
                                <div className="row-container" style={{ justifyContent: 'space-between' }}>
                                    <div style={{ width: '45%' }}>
                                        <Search
                                            label="Assign To"
                                            results={AssignTo}
                                            placeholder="Search GDA by ID"
                                            className="full-width search-default-input"
                                            reserveSpace
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => onCancelCall()}
                                            className="cancelCall"
                                            type="inline"
                                            actionType="submit"
                                        >
                                            Cancel Call
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <InputLabel label="Assigned To:" />
                                {assignedTo.map((assignTo) => (
                                    <div style={{ marginTop: '7px' }}>
                                        <Button type="link" icon={<Icon name="delete" size="small" root="common" />} content="" />
                                        <span style={{ marginLeft: '5px' }}>{assignTo}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="below-text"></div>
                            <div style={{ margin: '0 39px' }}>
                                <InputLabel label="Recommended GDAs" />
                                <DataTable
                                    data={dataEditAssign}
                                    selection={selectedRecommendedGDAs}
                                    onSelectionChange={(e) => setSelectedRecommendedGDAs(e)}
                                    selectionMode="multiple"
                                >
                                    <DataTable.Column field="name" header="Name" sortable />
                                    <DataTable.Column field="liveLocation" header="Live Location" sortable />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="modal-bottom-button-container">
                        <span className="m10Horizontal">
                            <Button type="secondary">Cancel</Button>
                        </span>
                        <span>
                            <Button type="primary">Confirm</Button>
                        </span>
                    </div>
                </form>
            </Modal>
            <ConfirmModal
                primaryButtonOptions={{
                    onClick: () => {},
                }}
                secondaryButtonOptions={{
                    onClick: onCancelConfirmModal,
                }}
                title="Cancel Call?"
                closeIcon
                onClose={() => onCancelConfirmModal()}
                open={cancelCallModal}
            />
        </>
    );
};

export default CallDetailsAssignGDAModal;
