import { IGDA } from '../../models/IGDA';

const gdas = [
    {
        uuid: '123',
        name: 'Ajinkya Rehane',
        phoneNumber: '9674823456',
        department: 'Common',
        status: 'active',
        bleTagNo: '24',
    },
    {
        uuid: '11234',
        name: 'Christopher Nolan',
        phoneNumber: '9674823456',
        department: 'Common',
        status: 'active',
        bleTagNo: '24',
    },
];

export const getAllGdas = () => new Promise((resolve) => resolve(gdas));

export const getGDABySearchTerm = (searchTerm: string) => new Promise<IGDA[]>((resolve) => {
    resolve(gdas.filter((gda) => gda.uuid.includes(searchTerm) || gda.name.toLocaleLowerCase().includes(searchTerm.toLowerCase())));
});
