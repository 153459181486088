/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import {
    Modal, Button,
} from '@scuf/common';
import IModalProps from '@scuf/common/dist/components/Modal/IModalProps';
import { IButtonProps } from '@scuf/common/dist/components/Button/IButtonProps';

interface Props extends IModalProps {
    title: string;
    primaryButtonOptions: IButtonProps
    secondaryButtonOptions: IButtonProps
    content?: ReactNode
}
const ConfirmModal = ({
    title, primaryButtonOptions, secondaryButtonOptions, content, ...others
}: Props) => (
    <Modal {...others} size={others.size || 'mini'}>
        <div>
            <h5>
                {title}
            </h5>
            {content}
            <Button type="secondary" content="Cancel" {...secondaryButtonOptions}>
                Cancel
            </Button>
            <Button type="primary" content="Confirm" {...primaryButtonOptions}>
                Confirm
            </Button>
        </div>
    </Modal>
);
export default ConfirmModal;
