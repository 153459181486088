import React, { ReactElement, ReactNode } from 'react';

import HeaderLayout from './Header/HeaderLayout';

interface Props {
  children: ReactNode
}

const AppShell = ({
    children,
}: Props): ReactElement => (
    <HeaderLayout>
        {children}
    </HeaderLayout>
);

export default AppShell;
