import React, { ReactElement, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Button, Modal, Tab,
} from '@scuf/common';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';

import EntityDetails from '../components/EntityDetails';

type GDAType = {
id:string,
name:string,
taskcount:number,
efficiency:string,
department:string,
location:string,
};

function genGDAEntry(): GDAType[] {
    return [{
        id: '1',
        name: 'Ajinkya Rahane',
        taskcount: 3,
        department: 'Orthopedics',
        efficiency: '45%',
        location: 'XRay lab',
    }, {
        id: '4324',
        name: 'Mahesh Thorat',
        taskcount: 6,
        department: 'C2DC',
        efficiency: '76%',
        location: 'Entrance',
    }];
}

const Home = (): ReactElement => {
    const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({
        id: '', name: '', department: '', efficiency: '', location: '',
    });

    const expanderTemplate = (currentrowdata:ICellData) => (
        <Button
            type="link"
            onClick={() => {
                setViewDetailsModalOpen(true);
                setSelectedRowData(currentrowdata.rowData);
            }}
        >
            View Details
        </Button>
    );

    return (
        <div>
            <DataTable
                data={genGDAEntry()}
                search
                rows={10}
            >
                <DataTable.Column field="id" header="ID" sortable />
                <DataTable.Column field="name" header="Name" sortable />
                <DataTable.Column field="taskcount" header="Task Count" sortable />
                <DataTable.Column field="department" header="Department" sortable />
                <DataTable.Column field="efficiency" header="Avg. Efficiency" sortable />
                <DataTable.Column field="location" header="Live Location" sortable />
                <DataTable.Column initialWidth="3rem" field="plane" header="Show" renderer={expanderTemplate} />
            </DataTable>

            <Modal open={viewDetailsModalOpen} onClose={() => setViewDetailsModalOpen(false)} size="large">

                <Tab defaultActiveIndex={0}>

                    <Tab.Pane title="Analysis">

                    </Tab.Pane>
                    <Tab.Pane title="Details">
                        <EntityDetails entity={selectedRowData} />
                    </Tab.Pane>
                </Tab>
            </Modal>
        </div>
    );
};

export default Home;
