import { Card } from '@scuf/common';
import React, { ReactElement } from 'react';

interface Props{
    entity:{
        id: string,
        name:string,
        department:string,
        efficiency:string,
        location:string,
    }}
const EntityDetails = ({ entity: { name, department, id } }:Props): ReactElement => (

    <div>
        <Card>
            <Card.Header title={`${name}: ${id}`}>
            </Card.Header>
            <Card.Meta>
                {`Department - ${department}`}
            </Card.Meta>
            <Card.Content>
                <div className="div-left">
                    <img alt="https://www.honeywell.com/content/dam/honeywellbt/en/images/logos/impact-by-honeywell-logo.png" src="https://images-na.ssl-images-amazon.com/images/I/41TI95AkTdL.jpg" height="250" />
                </div>
            </Card.Content>
            <Card.Footer align="right">
                This is foorter for the Details tab
            </Card.Footer>
        </Card>
    </div>
);

export default EntityDetails;
