/* eslint linebreak-style: ["error", "windows"] */
export const AssignTo = [

    { title: 'Phillip J. Fry' },
    { title: 'Professor Hubert J. Farnsworth' },
    { title: 'Turanga Leela' },
    { title: 'Bender' },
    { title: 'Dr. John A. Zoidberg' },
    { title: 'Scruffy' },
];

export const assignedTo = ['Ajinkya Rahane', 'Charis Patt'];

export const tabs = [
    'Unassigned Calls',
    'Assigned Calls',
    'Completed Calls',
    'Manage GDA',
];

export const labTabs = [
    'Assigned Calls',
    'Completed Calls',
    'Manage GDA',
];

export const gdaAssignTo = [
    { value: 'Phillip J. Fry', text: 'Phillip J. Fry' },
    { value: 'Professor Hubert J. Farnsworth', text: 'Professor Hubert J. Farnsworth' },
    { value: 'Turanga Leela', text: 'Turanga Leela' },
    { value: 'Bender', text: 'Bender' },
    { value: 'Dr. John A. Zoidberg', text: 'Dr. John A. Zoidberg' },
    { value: 'Scruffy', text: 'Scruffy' },
];
export const searchTracking = [
    { title: 'Phillip J. Fry' },
    { title: 'Professor Hubert J. Farnsworth' },
    { title: 'Turanga Leela' },
    { title: 'Bender' },
    { title: 'Dr. John A. Zoidberg' },
    { title: 'Scruffy' },
];

export const taskType = [
    { value: 'MRI', text: 'MRI' },
    { value: 'X-Ray', text: 'X-Ray' },
];

export const departmentList = [
    {
        value: 'Cardiology',
        text: 'Cardiology',
        name: 'Cardiology',
        code: 'Cardiology',
        description: 'Cardiology',
        zone: 'Ward 1',
    },
    {
        value: 'Dermatology',
        text: 'Dermatology',
        name: 'Dermatology',
        code: 'Dermatology',
        description: 'Dermatology',
        zone: 'Ward 1',
    },
    {
        value: 'Neurology',
        text: 'Neurology',
        name: 'Dermatology',
        code: 'Dermatology',
        description: 'Dermatology',
        zone: 'Ward 1',
    },
    {
        value: 'Ophthalmology',
        text: 'Ophthalmology',
        name: 'Ophthalmology',
        code: 'Ophthalmology',
        description: 'Ophthalmology',
        zone: 'Ophthalmology',
    },
    {
        value: 'Orthopedics',
        text: 'Orthopedics',
        name: 'Orthopedics',
        code: 'Orthopedics',
        description: 'Orthopedics',
        zone: 'Orthopedics',
    },
    {
        value: 'Inpatient Service (IP)',
        text: 'Inpatient Service (IP)',
        name: 'Inpatient Service (IP)',
        code: 'Inpatient Service (IP)',
        description: 'Inpatient Service (IP)',
        zone: 'Inpatient Service (IP)',
    },
];

export const departmentListSelect = [
    {
        value: 'Cardiology',
        text: 'Cardiology',
    },
    {
        value: 'Dermatology',
        text: 'Dermatology',
    },
    {
        value: 'Neurology',
        text: 'Neurology',
    },
    {
        value: 'Ophthalmology',
        text: 'Ophthalmology',
    },
    {
        value: 'Orthopedics',
        text: 'Orthopedics',
    },
    {
        value: 'Inpatient Service (IP)',
        text: 'Inpatient Service (IP)',
    },
];
export const questiontListSelect = [
    {
        value: 'What is your place?',
        text: 'What is your place?',
    },
    {
        value: 'What is your middle name?',
        text: 'What is your middle name?',
    },
    {
        value: 'Name of your first school?',
        text: 'Name of your first school?',
    },
];

export const testOptions = [
    { value: 'MRI', text: 'MRI' },
    { value: 'X-Ray', text: 'X-Ray' },
];
export const patientOptions = [
    { value: 'ABC', text: 'ABC' },
    { value: 'XYZ', text: 'XYZ' },
    { value: 'PQR', text: 'PQR' },
];

export const dataAssignTest = [
    {
        patient_name: 'Aron Davis',
        test_name: 'MRI',
        test_date: '06/24/2021',
    },
    {
        patient_name: 'Garven Dresis',
        test_name: 'X-Ray',
        test_date: '06/24/2021',
    },
    {
        patient_name: 'David JP',
        test_name: 'MRI',
        test_date: '06/24/2021',
    },
];

export const dataUnassigned = [
    {
        task_id: '3356',
        taskType: 'Medication Return',
        callDateType: '26-sept-21 01:20 PM',
        sourse: 'C2DC',
        destination: 'Pathology',
        callBy: 'Ramesh K',
        priority: 'Low',
        timeElapsed: '2 min',
    },
    {
        task_id: '3355',
        taskType: 'Patient Return',
        callDateType: '26-sept-21 01:20 PM',
        sourse: 'XRAY',
        destination: 'C3DC',
        callBy: 'Leo. D',
        priority: 'Low',
        timeElapsed: '21 min',
    },
];

export const dataEditAssign = [
    {
        name: 'Anjikya Rahane',
        liveLocation: 'C2DC',
        id: '123',
    },
];
export const dataPendingGDAWorkflow = [
    {
        UHID: '1',
        patientName: 'Anjikya Rahane',
        pickupTime: '12:30 pm',
        duration: '30 min',
        dropTime: '1 pm',
        status: 'Pending',
    },
];
export const dataCompleteGDAWorkflow = [
    {
        UHID: '2',
        patientName: 'Ramesh K',
        pickupTime: '12:30 pm',
        duration: '30 min',
        dropTime: '1 pm',
        status: 'Completed',
    },
];
export const dataTotalGDAWorkflow = [
    {
        UHID: '1',
        patientName: 'Anjikya Rahane',
        pickupTime: '12:30 pm',
        duration: '30 min',
        dropTime: '1 pm',
        status: 'Pending',
    }, {
        UHID: '2',
        patientName: 'Ramesh K',
        pickupTime: '12:30 pm',
        duration: '30 min',
        dropTime: '1 pm',
        status: 'Completed',
    },
];

export const dataAssigned = [
    {
        task_id: '3356',
        taskType: 'Medication Return',
        source: 'C2DC',
        destination: 'Pathology',
        assignedTo: 'Ramesh K',
        assignedDateTime: '26-sept-21 01:20 PM',
        priority: 'Low',
        status: 'Pending',
    },
    {
        task_id: '3355',
        taskType: 'Patient Return',
        source: 'XRAY',
        destination: 'C3DC',
        assignedTo: 'Leo. D',
        assignedDateTime: '26-sept-21 01:20 PM',
        priority: 'Low',
        status: 'In-Progress',
    },
];
export const dataComplete = [
    {
        task_id: '3356',
        taskType: 'Medication Return',
        source: 'C2DC',
        destination: 'Pathology',
        assignedTo: 'Ramesh K',
        completeDateTime: '26-sept-21 01:20 PM',
        priority: 'Low',
        status: 'Pending',
    },
    {
        task_id: '3355',
        taskType: 'Patient Return',
        source: 'XRAY',
        destination: 'C3DC',
        assignedTo: 'Leo. D',
        completeDateTime: '26-sept-21 01:20 PM',
        priority: 'Low',
        status: 'In-Progress',
    },
];
export const testTypeDetails = [
    {
        value: 'MRI',
        text: 'MRI',
        name: 'MRI',
        code: 'MRI',
        decription: 'MRI',
        dept: 'Pathology',
        conductBy: 'Ramesh K',
        conductOn: '26-sept-21 01:20 PM',
        suggestBy: 'ABC',
        status: 'Pending',
    },
    {
        value: 'X-RAY',
        text: 'X-RAY',
        name: 'X-RAY',
        code: 'X-RAY',
        decription: 'X-RAY',
        dept: 'Pathology',
        conductBy: 'Ramesh K',
        conductOn: '26-sept-21 01:20 PM',
        suggestBy: 'ABC',
        status: 'In-Progress',
    },
];
export const patientTypeDetails = [
    {
        UHID: '1/2021',
        name: 'Nisha',
        testType: 'MRI',
        testDate: '03-03-2021',
        fromTo: 'C2DC-X-Ray',
    },
    {
        UHID: '13/2021',
        name: 'Shyam',
        testType: 'X-RAY',
        testDate: '03-03-2021',
        fromTo: 'C2DC-2DECHO',
    },
];
export const bleHardwareDetails = [
    {
        name: 'ABC',
        version: '1.1',
        singleStrength: '1',
        battery: 'good',
        status: 'Active',
    },
    {
        name: 'XYZ',
        version: '1.1',
        singleStrength: '2',
        battery: 'good',
        status: 'InActive',
    },
];
export const taskDetails = [
    {
        taskID: '1',
        name: 'GetMedicationDone',
        decription: 'Get Medication Done',
        targetEntity: 'GDA',
        estimateTime: '20 min',
        status: 'Active',
    },
    {
        taskID: '2',
        name: 'PatientTrasfer',
        decription: 'Patient Trasfer',
        targetEntity: 'GDA',
        estimateTime: '30 min',
        status: 'Inactive ',
    },
];
export const dataPatient = [
    {
        UHID: '3356',
        firstName: 'AAA',
        lastName: 'CCC',
        test: 'MRI',
        joinDate: '26-sept-21 01:20 PM',
        top: '20',
        left: '60',

    },
    {
        UHID: '3357',
        firstName: 'XXX',
        lastName: 'ZZZ',
        test: 'MRI',
        joinDate: '26-sept-21 01:20 PM',
        top: '40',
        left: '60',
    },
];
export const dataPatientMap = [
    {
        top: '20',
        left: '60',

    },
    {
        top: '40',
        left: '70',
    },
];
export const dataGda = [
    {
        gda_id: '3356',
        GDAName: 'Ramesh K',
        department: 'Medication Return',
        phone_number: '768-473-9788',
        status: 'Available',
        tag_no: '34',
        live_location: 'C2DC',
    },
    {
        gda_id: '3355',
        GDAName: 'Leo. D',
        department: 'Patient Return',
        phone_number: '768-473-9788',
        status: 'Inactive',
        tag_no: '-',
        live_location: 'XRAY',
    },
];

export const dataPatientWorkflow = [
    {
        UHID: '1',
        name: 'ABC XYZ',
        testType: 'MRI',
        testDate: '09-03-2021',
        fromToLocation: 'C2DC-Radiology',
    },
    {
        UHID: '2',
        name: 'Ajinkya Rahane',
        testType: 'MRI',
        testDate: '09-03-2021',
        fromToLocation: 'C2DC-Radiology',
    },

];
export const dataSystemBleGateway = [
    {
        bleId: '3356',
        bleName: 'GT-Main Entrance',
        macAddress: 'F0:EE:D1:3F:6B:P2',
        password: 'GEN/26',
        ipAddress: '20.45.99.10',
        zone: 'C2DC',
    },
    {
        bleId: '3355',
        bleName: 'GT Entrance',
        macAddress: 'F0:EE:D1:3F:6B:P1',
        password: 'GEN/27',
        ipAddress: '20.45.99.10',
        zone: 'XRAY',
    },
];

export const dataSystemBleTag = [
    {
        bleId: '3357',
        bleName: 'GT-Main Entrance',
        macAddress: 'F0:EE:D1:3F:6B:P2',
        password: 'GEN/26',
        ipAddress: '20.45.99.10',
        zone: 'C2DC',
    },

];
export const dataSystemInfrastructure = [
    {
        bleId: '3357',
        bleName: 'GT-Main Entrance',
        macAddress: 'F0:EE:D1:3F:6B:P2',
        password: 'GEN/26',
        ipAddress: '20.45.99.10',
        zone: 'C2DC',
    },

];
export const dataSystemLab = [
    {
        bleId: '3357',
        bleName: 'GT-Main Entrance',
        macAddress: 'F0:EE:D1:3F:6B:P2',
        password: 'GEN/26',
        ipAddress: '20.45.99.10',
        zone: 'C2DC',
    },

];
