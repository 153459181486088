import {
    CallStatus, ICall, ICallDetails, TransferMode,
} from '../../models/ICall';

const calls: ICallDetails[] = [];

export const createCallApi = (call: ICall) => new Promise<boolean>((resolve) => {
    const uuid = '1';

    let gdaRequired = 1;
    if (call.transferMode === TransferMode.WHEELCHAIR) {
        gdaRequired = 1;
    } else if (call.transferMode === TransferMode.STRETCHER) {
        gdaRequired = 2;
    }
    let status = CallStatus.UNASSIGNED;
    if (call.assignedGDAs?.length) {
        status = CallStatus.ASSIGNED;
    }
    const createdOn = new Date().toUTCString();
    calls.push({
        uuid,
        gdaRequired,
        createdOn,
        status,
        ...call,
    });
    resolve(true);
});

export const getAllAssignedCalls = () => new Promise<ICallDetails[]>((resolve) => {
    resolve(calls.filter((call) => call.status === CallStatus.ASSIGNED));
});

export const getAllUnassignedCalls = () => new Promise<ICallDetails[]>((resolve) => {
    resolve(calls.filter((call) => call.status === CallStatus.UNASSIGNED));
});

export const getAllCompletedCalls = () => new Promise<ICallDetails[]>((resolve) => {
    resolve(calls.filter((call) => call.status === CallStatus.COMPLETED));
});
