/* eslint linebreak-style: ["error", "unix"] */
import React, { ReactElement, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Icon, Tab, Modal, Button, Input, Radio, InputLabel, Divider, Grid, DatePicker, Select,
} from '@scuf/common';
import genActiveEntry from '../json/activePatient.json';
import genInactiveEntry from '../json/inactivePatient.json';
import { departmentListSelect } from '../constanFile.js';

const IPDDashboard = (): ReactElement => {
    const [isAddModalOpenGateway, setAddModalGateway] = useState(false);
    const [isEditModalGateway, setEditModalGateway] = useState(false);
    const [isDeleteModalGateway, setDeleteModalGateway] = useState(false);
    const [isAddModalOpenTag, setAddModalTag] = useState(false);
    const [contacts, setContacts] = useState(genActiveEntry);
    const [addFormData, setAddFormData] = useState({
        UHID: '',
        patientName: '',
        admittedDate: '',
        department: '',
        wardBedNo: '',
        bleTagNo: '',
        phoneNumber: '',
    });

    const handleAddFormChange = (fieldName, fieldValue) => {
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    const handleAddFormSubmit = () => {
        const newContact = {
            UHID: addFormData.UHID,
            patientName: addFormData.patientName,
            admittedDate: addFormData.admittedDate,
            department: addFormData.department,
            wardBedNo: addFormData.wardBedNo,
            bleTagNo: addFormData.bleTagNo,
            phoneNumber: addFormData.phoneNumber,
        };
        const newContacts = [...contacts, newContact];
        setContacts(newContacts);
        setAddModalGateway(false);
    };
    const handleDeleteSubmit = () => {
        if (window.confirm('Are you sure you wish to delete this patient?')) {
            alert('Your anser is "Yes!"');
        }
    };

    const handleEditFormSubmit = () => {
        const newContact = {
            UHID: addFormData.UHID,
            patientName: addFormData.patientName,
            admittedDate: addFormData.admittedDate,
            department: addFormData.department,
            wardBedNo: addFormData.wardBedNo,
            bleTagNo: addFormData.bleTagNo,
            phoneNumber: addFormData.phoneNumber,
        };
        const newContacts = [...contacts, newContact];
        setContacts(newContacts);

        setAddModalGateway(false);
    };

    const expanderActionInactiveTemplate = () => (
        <div>
            <Button
                type="link"
                icon={<Icon root="common" name="edit" size="small" />}
                content=""
                iconPosition="left"
                onClick={() => setEditModalGateway(true)}
            />
            <Button
                type="link"
                icon={<Icon root="global" name="delete" size="small" />}
                content=""
                iconPosition="left"
                onClick={() => handleDeleteSubmit()}

                // onClick={() => { }

            />
        </div>
    );

    return (
        <div style={{ marginLeft: 10 }}>
            <Tab defaultActiveIndex={0}>
                <Tab.Pane title="Active">
                    <div>
                        <div className="addBtnHead">
                            <Button type="primary" icon={<Icon name="user-add" size="small" root="common" />} content="Add Patient" onClick={() => setAddModalGateway(true)} />
                        </div>
                        <DataTable data={contacts} search searchPlaceholder="Search Records">
                            <DataTable.Column field="UHID" header="UHID" sortable />

                            <DataTable.Column field="patientName" header="Patient Name" sortable />
                            <DataTable.Column field="admittedDate" header="D.O.A" sortable />
                            <DataTable.Column field="department" header="Department" sortable />
                            <DataTable.Column field="wardBedNo" header="Ward/Bed No." sortable />
                            <DataTable.Column field="bleTagNo" header="BLE Tag No." sortable />
                            <DataTable.Column field="phoneNumber" header="Contact Number" sortable />
                            <DataTable.Column field="liveLocation" header="Live Location" sortable />
                            <DataTable.Column field="plane" header="Action" renderer={expanderActionInactiveTemplate} />
                        </DataTable>
                    </div>
                </Tab.Pane>
                <Tab.Pane title="Inactive">
                    <div>

                        <DataTable
                            data={genInactiveEntry}
                            search
                            rows={10}
                        >
                            <DataTable.Column field="UHID" header="UHID" sortable />
                            <DataTable.Column field="patientName" header="Name" sortable />
                            <DataTable.Column field="admittedDate" header="D.O.A" sortable />
                            <DataTable.Column field="department" header="Department" sortable />
                            <DataTable.Column field="wardBedNo" header="Ward/Bed No." sortable />
                            <DataTable.Column field="ble_tag_no" header="BLE Tag No." sortable />
                            <DataTable.Column field="phone_number" header="Contact Number" sortable />
                            <DataTable.Column field="live_location" header="Live Location" sortable />
                            <DataTable.Column field="plane" header="Action" renderer={expanderActionInactiveTemplate} />

                        </DataTable>
                    </div>
                </Tab.Pane>
            </Tab>
            <Modal
                data-testid="addPatientModal"
                className="modal500"
                size="large"
                closeIcon
                onClose={() => setAddModalGateway(false)}
                open={isAddModalOpenGateway}
            >

                <div>
                    <h2>Add Patient</h2>
                    <Divider fitted />
                    <form onSubmit={handleAddFormSubmit}>
                        <div className="m10">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="UHID"
                                            label="UHID"
                                            className="w14"
                                            name="UHID"
                                            onChange={(value) => (handleAddFormChange('UHID', value))}

                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="Patient Name"
                                            label="Patient Name"
                                            className="w14"
                                            name="patientName"
                                            onChange={(value) => (handleAddFormChange('patientName', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="Contact"
                                            label="Contact"
                                            className="w14"
                                            name="phoneNumber"
                                            onChange={(value) => (handleAddFormChange('phoneNumber', value))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <DatePicker
                                            placeholder="Select Date"
                                            type="date"
                                            label="Date Of Joining"
                                            className="w14"
                                            name="admittedDate"
                                            onTextChange={(value) => (handleAddFormChange('admittedDate', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Select
                                            label="Department"
                                            search
                                            placeholder="Select Department"
                                            className="w14"
                                            options={departmentListSelect}
                                            name="department"
                                            onChange={(value) => (handleAddFormChange('department', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="Ward/Bed No."
                                            label="Ward/Bed No."
                                            className="w14"
                                            name="wardBedNo"
                                            onChange={(value) => (handleAddFormChange('wardBedNo', value))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </div>

                        <div className="modal-bottom-button-container">
                            <span>
                                <Button type="primary" actionType="submit">Save</Button>
                            </span>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                data-testid="addPatientModal"
                className="modal500"
                size="large"
                closeIcon
                onClose={() => setEditModalGateway(false)}
                open={isEditModalGateway}
            >
                <div>
                    <h2>Edit Patient</h2>
                    <Divider fitted />
                    <form onSubmit={handleEditFormSubmit}>
                        <div className="m10">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="UHID"
                                            label="UHID"
                                            className="w14"
                                            name="UHID"
                                            onChange={(value) => (handleAddFormChange('UHID', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="Patient Name"
                                            label="Patient Name"
                                            className="w14"
                                            name="patientName"
                                            onChange={(value) => (handleAddFormChange('patientName', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="Contact"
                                            label="Contact"
                                            className="w14"
                                            name="phoneNumber"
                                            onChange={(value) => (handleAddFormChange('phoneNumber', value))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <DatePicker
                                            placeholder="Select Date"
                                            type="date"
                                            label="Date Of Joining"
                                            className="w14"
                                            name="admittedDate"
                                            onTextChange={(value) => (handleAddFormChange('admittedDate', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Select
                                            label="Department"
                                            search
                                            placeholder="Select Department"
                                            className="w14"
                                            options={departmentListSelect}
                                            name="department"
                                            onChange={(value) => (handleAddFormChange('department', value))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Input
                                            placeholder="Ward/Bed No."
                                            label="Ward/Bed No."
                                            className="w14"
                                            name="wardBedNo"
                                            onChange={(value) => (handleAddFormChange('wardBedNo', value))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>

                        </div>

                        <div className="modal-bottom-button-container">
                            <span className="m10Horizontal">
                                <Button type="secondary" actionType="submit">Cancel</Button>
                            </span>
                            <span>
                                <Button type="primary" actionType="submit">Confirm</Button>
                            </span>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal closeIcon onClose={() => setAddModalTag(false)} open={isAddModalOpenTag}>
                <form style={{ alignContent: 'center' }}>
                    <h1>Ble Tag Registration</h1>
                    <Divider fitted />
                    <div>
                        <div>
                            <span style={{ margin: 10 }}>
                                <Input placeholder="Ble Name" label="Ble Name" />
                            </span>
                            <span style={{ margin: 10 }}>
                                <Input placeholder="MAC Address" label="MAC Address" />
                            </span>
                        </div>
                        <div style={{ width: 580 }}>
                            <span style={{ margin: 10 }}>
                                <Input placeholder="Ble Tag" label="Ble Tag" />
                            </span>
                            <span style={{ margin: 10 }}>
                                <div className="moving">
                                    <InputLabel label="Fixed/Moving" />
                                </div>
                                <div style={{ marginLeft: 375 }}>
                                    <Radio label="Fixed" name="fix_move" value="Fixed" />
                                    <Radio label="Moving" name="fix_move" value="Moving" />
                                </div>
                            </span>
                        </div>
                        <div className="statusDivTag">

                            <InputLabel label="Status" />
                        </div>
                        <div style={{ width: 500 }}>
                            <Radio label="Yes" name="status" value="Yes" />
                            <Radio label="No" name="status" value="No" />
                        </div>
                        <span style={{ margin: 10 }}>
                            <Button type="primary" actionType="submit">Submit</Button>
                        </span>
                    </div>
                </form>
            </Modal>
            <Modal open={isDeleteModalGateway} onClose={() => setDeleteModalGateway(false)} size="large">

            </Modal>

        </div>
    );
};
export default IPDDashboard;
