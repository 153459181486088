/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import { DataTable } from '@scuf/datatable';
import {
    Tab, Button, Modal,
} from '@scuf/common';
import PageLayoutBle from '../../components/PageLayoutBle';
import { dataSystemBleGateway, dataSystemBleTag } from '../../constanFile.js';
import AddDeviceModal from '../../components/Modals/AddModalDevice';

function BLE() {
    const [isAddDevice, setAddDevice] = useState(false);
    return (
        <div>
            <PageLayoutBle data-testid="BLE" title="BLE" headerRight={<Button type="primary" content="Add Device" onClick={() => setAddDevice(true)} />}>
                <Tab defaultActiveIndex={0}>
                    <Tab.Pane title="Gateway">
                        <div>
                            <DataTable data={dataSystemBleGateway} search searchPlaceholder="Search Records">
                                <DataTable.Column field="bleId" header="ID" sortable />
                                <DataTable.Column field="bleName" header="BLE Name" sortable />
                                <DataTable.Column field="macAddress" header="MAC Address" sortable />
                                <DataTable.Column field="password" header="Password" sortable />
                                <DataTable.Column field="ipAddress" header="IP Address" sortable />
                                <DataTable.Column field="zone" header="Zone Group" sortable />
                            </DataTable>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane title="Tag/Wrist Band">
                        <div>
                            <DataTable data={dataSystemBleTag} search searchPlaceholder="Search Records">
                                <DataTable.Column field="bleId" header="ID" sortable />
                                <DataTable.Column field="bleName" header="BLE Name" sortable />
                                <DataTable.Column field="macAddress" header="MAC Address" sortable />
                                <DataTable.Column field="password" header="Password" sortable />
                                <DataTable.Column field="ipAddress" header="IP Address" sortable />
                                <DataTable.Column field="zone" header="Zone Group" sortable />
                            </DataTable>
                        </div>
                    </Tab.Pane>
                </Tab>
                <Modal className="modal40" size="mini" closeIcon onClose={() => setAddDevice(false)} open={isAddDevice}>
                    <AddDeviceModal />
                </Modal>
            </PageLayoutBle>

        </div>
    );
}
export default BLE;
