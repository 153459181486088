import React from 'react';
import {
    Button, Divider,
} from '@scuf/common';
import TaskData from './TaskData';
const assignedTo = ['Ajinkya Rahane', 'Charis Patt'];
function CompleteEditModal() {
    return (
        <div>
            <h1>Call Details</h1>
            <form>
                <div className="m10">
                    <TaskData />
                    <Divider />

                    <div className="EditUnassignAssignTo">
                        <p>
                            <b>Assigned To : </b>
                        </p>
                    </div>
                    <div className="textLeft mt10">

                        {assignedTo.map((assignTo) => (
                            <p className="ml25">
                                {assignTo}
                            </p>
                        ))}
                    </div>
                    <div className="textLeft mt10">
                        <span><b>Completed Time : </b></span>
                        <span>26-Sept-21,2:50 PM </span>
                    </div>
                </div>
                <div className="divConfirmCancel">
                    <span className="m10">
                        <Button type="primary">Close</Button>
                    </span>
                </div>
            </form>
        </div>
    );
}
export default CompleteEditModal;
