import { ReactNode } from 'react';
import Home from './pages/Home';
import IPDDashboard from './pages/IPDDashboard';
import AssignTest from './pages/AssignTest';
import GDADashboard from './pages/GDADashboard/GDADashboard';
import LabDashboard from './pages/LabDashboard/LabDashboard';
import BLERegitration from './pages/BLERegistration';
import Tracking from './pages/Tracking';
import BLEDashboard from './pages/BLEDashboard/BLEDashboard';
import TestType from './pages/Test/TestType';
import TaskMaster from './pages/Task/TaskMaster';
import Department from './pages/Department/Department';
import BLEHardware from './pages/BLEHardware/BLEHardware';
import NurseWorkflow from './pages/Workflow/nurseWorkflow';
import PatientWorkflow from './pages/Workflow/PatientWorkflow';
import GdaWorkflow from './pages/Workflow/GDAWorkflow';
import DoctorDashboard from './pages/Doctor/DoctorDashboard';
import NurseDashboard from './pages/Nurse/NurseDashboard';
import Profile from './pages/profile';
import notification from './pages/notification';
import HeadNurseDashboard from './pages/HeadNurse/HeadNurseDashboard';
import DoctorReport from './pages/Report/DoctorReport';
import NurseReport from './pages/Report/NurseReport';
import PatientReport from './pages/Report/PatientReport';
import PatientWorkflowView from './pages/Workflow/PatientWorkflowView';

export interface IRoutes {
  name: string,
  path: string,
  Component: ReactNode
}

export default [
    {
        name: 'IPD Dashboard',
        path: '/ipd-dashboard',
        Component: IPDDashboard,
    },
    {
        name: 'Assign Test',
        path: '/assign-test',
        Component: AssignTest,
    },
    {
        name: 'GDA Dashboard',
        path: '/gda-dashboard',
        Component: GDADashboard,
    },
    {
        name: ' Lab Dashboard',
        path: '/lab-dashboard',
        Component: LabDashboard,
    },
    {
        name: ' BLE Dashboard',
        path: '/ble-dashboard',
        Component: BLEDashboard,
    },
    {
        name: 'BLERegitration',
        path: '/ble-regitration',
        Component: BLERegitration,
    },
    {
        name: 'Tracking',
        path: '/tracking',
        Component: Tracking,
    },
    {
        name: 'TestType',
        path: '/test-type',
        Component: TestType,
    },
    {
        name: 'TaskMaster',
        path: '/task-master',
        Component: TaskMaster,
    },
    {
        name: 'Department',
        path: '/department',
        Component: Department,
    },
    {
        name: 'BLEHardware',
        path: '/ble-hardware',
        Component: BLEHardware,
    },
    {
        name: 'PatientWorkflowView',
        path: '/patient-workflow-view',
        Component: PatientWorkflowView,
    },

    {
        name: 'NurseWorkflow',
        path: '/nurse-workflow',
        Component: NurseWorkflow,
    },
    {
        name: 'PatientWorkflow',
        path: '/patient-workflow',
        Component: PatientWorkflow,
    },
    {
        name: 'GdaWorkflow',
        path: '/gda-workflow',
        Component: GdaWorkflow,
    },
    {
        name: 'DoctorDashboard',
        path: '/doctor-dashboard',
        Component: DoctorDashboard,
    },
    {
        name: 'NurseDashboard',
        path: '/nurse-dashboard',
        Component: NurseDashboard,
    },
    {
        name: 'HeadNurseDashboard',
        path: '/headnurse-dashboard',
        Component: HeadNurseDashboard,
    },
    {
        name: 'Profile',
        path: '/profile',
        Component: Profile,
    },
    {
        name: 'notification',
        path: '/notification',
        Component: notification,
    },
    {
        name: 'doctor-report',
        path: '/doctor-report',
        Component: DoctorReport,
    },
    {
        name: 'nurse-report',
        path: '/nurse-report',
        Component: NurseReport,
    },
    {
        name: 'patient-report',
        path: '/patient-report',
        Component: PatientReport,
    },
    {
        name: 'Home',
        path: '/',
        Component: Home,
    },
];
